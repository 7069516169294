import AuthSelectors from "../../../modules/auth/selectors";
import { Sparkles } from "lucide-react";

const WelcomeBar = () => {
  const { user } = AuthSelectors();
  return (
    <div className="flex flex-col w-full">
      <div className="flex gap-2 w-full">
        <div className="animate-wiggle">
          <Sparkles color={"#ffbf00"} className="animate-pulse" />
        </div>
        <h3 className="font-bold">Welcome, {user?.fullName}</h3>
      </div>
    </div>
  );
};

export default WelcomeBar;
