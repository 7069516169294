import {
  FlowEnum,
  IChartDetails,
  IConnectivityAnalysis,
} from "../../../../../utils/types";
import { Table as AntTable, TableProps, Tooltip } from "antd";
import StatValue from "../../../../../components/ui/StatValue";
import CardWrapper from "../../../../../components/CardWrapper";
import { IoMdInformationCircleOutline } from "react-icons/io";
type ColumnsType<T> = TableProps<T>["columns"];

const Table = ({
  list,
  flow,
}: {
  list: IChartDetails<IConnectivityAnalysis>;
  flow: FlowEnum;
}) => {
  const { data, error, loading, needSubscription } = list;

  const columnsOutFlow: ColumnsType<any> = [
    {
      title: "Rank",
      dataIndex: "rank",
      // sorter: true,
    },
    // {
    //   title: "Name",
    //   dataIndex: "name",
    // },
    {
      title: "Email",
      dataIndex: "email",
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   render: (_: any, record: any) => (
    //     <div className="flex flex-col">
    //       <span className="font-bold">{record.name}</span>
    //       <span className="text-placeholder">{record.email}</span>
    //     </div>
    //   ),
    // },
    {
      title: (
        <Tooltip
          color={"#fff"}
          overlayInnerStyle={{ color: "#000" }}
          placement="topLeft"
          arrow={false}
          title={"Sent"}
          className="cursor-pointer flex gap-1 items-center"
        >
          <span>Sent</span>
          <IoMdInformationCircleOutline color={"#707070 "} />
        </Tooltip>
      ),
      dataIndex: "sent",
      hidden: flow !== FlowEnum.OUTFLOW,
      render: (_: any, record: any) => (
        <StatValue
          percentType={record?.messages?.percentageType}
          percent={record?.messages?.percent}
          text={record?.messages?.value}
        />
      ),
    },
    {
      title: (
        <Tooltip
          color={"#fff"}
          overlayInnerStyle={{ color: "#000" }}
          placement="topLeft"
          arrow={false}
          title={"Received"}
          className="cursor-pointer flex gap-1 items-center"
        >
          <span>Received</span>
          <IoMdInformationCircleOutline color={"#707070 "} />
        </Tooltip>
      ),
      dataIndex: "Received",
      hidden: flow !== FlowEnum.INFLOW,
      render: (_: any, record: any) => (
        <StatValue
          percentType={record?.messages?.percentageType}
          percent={record?.messages?.percent}
          text={record?.messages?.value}
        />
      ),
    },
    {
      title: (
        <Tooltip
          color={"#fff"}
          overlayInnerStyle={{ color: "#000" }}
          placement="topLeft"
          arrow={false}
          title={"Messages"}
          className="cursor-pointer flex gap-1 items-center"
        >
          <span>Messages</span>
          <IoMdInformationCircleOutline color={"#707070 "} />
        </Tooltip>
      ),
      dataIndex: "messages",
      hidden: flow !== FlowEnum.ALL,
      render: (_: any, record: any) => (
        <StatValue
          percentType={record?.messages?.percentageType}
          percent={record?.messages?.percent}
          text={record?.messages?.value}
        />
      ),
    },
    {
      title: "Involvement",
      dataIndex: "involvement",
      render: (_: any, record: any) => (
        <StatValue
          percentType={record?.involvement?.percentageType}
          percent={record?.involvement?.percent}
          text={record?.involvement?.value}
        />
      ),
    },
    {
      title: "Response Time",
      dataIndex: "responseTime",
      render: (_: any, record: any) => (
        <StatValue
          percentType={record?.responseTime?.percentageType}
          percent={record?.responseTime?.percent}
          text={record?.responseTime?.value}
        />
      ),
    },
  ];
  return (
    <CardWrapper
      title="Connections Analysis"
      tooltip="Domain analysis ranking score by volume, response time and involvement"
      loading={loading}
      error={error}
      needSubscription={needSubscription}
      content={
        <AntTable
          scroll={{ x: "max-content" }}
          columns={columnsOutFlow}
          rowKey={(record) => record.rank}
          dataSource={data}
          size="small"
          sortDirections={["ascend", "descend"]}
          loading={loading}
          className="table-top-align min-h-[250px]"
          // onChange={handleTableChange}
        />
      }
    />
  );
};

export default Table;
