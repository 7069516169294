import { useEffect, useState } from "react";
import { useAlertsContext } from "../../../../hooks/useAlertsContext";
import { ITicket } from "../../../../utils/types";
import {
  AddReview,
  AddTicket,
  GetReviews,
  GetTickets,
} from "../services/service";

const useTickets = () => {
  const { toastError, toastSuccess } = useAlertsContext();
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState<ITicket[]>([]);
  const [reviews, setReviews] = useState<{
    customerService: number;
    userExperience: number;
  }>({
    customerService: -1,
    userExperience: -1,
  });

  const addTicket = async (ticket: ITicket) => {
    setLoading(true);
    await AddTicket(ticket)
      .then((response) => {
        toastSuccess(response.message);
        fetchTickets();
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };
  const fetchTickets = async () => {
    await GetTickets()
      .then((response) => {
        setTickets(response.data);
      })
      .catch((error) => {});
  };
  const addReview = async (value: number, type: string) => {
    await AddReview({ value, type })
      .then((response) => {
        toastSuccess(response.message);
      })
      .catch((error) => {});
  };
  const fetchReviews = async () => {
    await GetReviews()
      .then((response) => {
        setReviews(response.data);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    fetchTickets();
    fetchReviews();
  }, []);

  return {
    loading,
    tickets,
    addTicket,
    addReview,
    fetchTickets,
    reviews,
  };
};
export default useTickets;
