import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthActions from "../../modules/auth/actions";
import { useAppDispatch } from "../../hooks/useStore";
import { useAlertsContext } from "../../hooks/useAlertsContext";
import { useMsal } from "@azure/msal-react";
import {
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  InteractionType,
} from "@azure/msal-browser";
import { tokenRequest } from "../../utils/msAuthConfig";
import { CgSpinner } from "react-icons/cg";

const MSCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { msLogIn } = AuthActions();
  const dispatch = useAppDispatch();
  const { toastError } = useAlertsContext();
  const { instance, accounts } = useMsal();

  const handleMSLogin = async (code: string) => {
    console.log("handleMSLogin", { code });

    try {
      const callbackId = instance.addEventCallback(
        async (message: EventMessage) => {
          console.log("callbackId", { message });
          switch (message.eventType) {
            case EventType.LOGIN_SUCCESS:
            case EventType.ACQUIRE_TOKEN_SUCCESS:
              if (message.interactionType === InteractionType.Redirect) {
                const payload = message.payload;
                console.log({
                  message,
                  payload,
                  instance,
                });
                if (payload && "accessToken" in payload) {
                  const accessToken = payload.accessToken;
                  // Continue with the logic to handle the access token, such as dispatching it
                  dispatch(msLogIn(accessToken, () => navigate("/Dashboard")));
                } else {
                  console.error("AccessToken not found in payload", payload);
                  let activeAccount = instance.getActiveAccount();
                  if (!activeAccount && accounts.length > 0) {
                    activeAccount = accounts[0]; // Use the first account in the list
                    instance.setActiveAccount(activeAccount);
                  }
                  console.log({
                    activeAccount,
                  });
                  if (!activeAccount) {
                    toastError("No account selected!");
                    console.error("No active account available.");
                    navigate("/");
                    return;
                  }

                  const accessTokenRequest = {
                    ...tokenRequest,
                    account: activeAccount,
                  };
                  console.log({ accessTokenRequest });

                  try {
                    const accessTokenResponse = await instance
                      .acquireTokenSilent(accessTokenRequest)
                      .then((response) => {
                        const accessToken = response.accessToken;
                        dispatch(
                          msLogIn(accessToken, () => navigate("/Dashboard"))
                        );
                      })
                      .catch((e) => {
                        console.error("acquireTokenSilent failed:", e);
                        toastError("Failed to acquire token silently.");
                        throw e;
                      });
                  } catch (error: any) {
                    if (error instanceof InteractionRequiredAuthError) {
                      console.log("Token expired, redirecting...");
                      await instance.acquireTokenRedirect(tokenRequest);
                    } else {
                      console.error("Error during token acquisition:", error);
                      toastError(
                        error?.errorMessage ||
                          "Failed to acquire token. Please try again."
                      );
                      throw error;
                    }
                  }
                }
              }
              break;

            case EventType.ACQUIRE_TOKEN_FAILURE:
              toastError("ACQUIRE_TOKEN_FAILURE");
              break;

            case EventType.LOGIN_FAILURE:
              toastError("LOGIN_FAILURE");
              break;

            default:
              console.warn("Unhandled event type:", message.eventType);
              break;
          }
        }
      );
    } catch (error) {
      console.error("Error in handleMSLogin:", error);
      toastError("Failed to login!");
      navigate("/");
    }
  };

  useEffect(() => {
    // const searchParams = new URLSearchParams(location.search);
    const hashParams = new URLSearchParams(location.hash.substring(1));
    const code = hashParams.get("code");
    const error = hashParams.get("error");
    console.log("useEffect", { s: JSON.stringify(location), hashParams });

    if (code) {
      handleMSLogin(code);
    } else if (error) {
      toastError("Failed to login!");
      console.log("handleMSLogin", error);
      navigate("/");
    } else if (!code && error) {
      navigate("/");
    }
    // Redirect back to your main app page
    else navigate("/");
  }, [instance]);

  return (
    <div className="p-4">
      <div className="flex gap-2 items-center">
        <CgSpinner className="animate-spin size-8 text-primaryBlue" />
        <h1 className="animate-shine text-lg font-bold">Redirecting...</h1>
      </div>
      <p>Please wait a while. You are being redirected.</p>
    </div>
  );
};

export default MSCallback;
