import React, { useEffect, useState } from "react";
import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import ListWithAction from "../../../components/list/ListWithAction";
import Section from "../../../layouts/Section";
import Invoices from "./components/Invoices";
import { GetBillings } from "./services/service";
import { IBilling } from "../../../utils/types";
import Loading from "../../../components/ui/Loading";
import AuthSelectors from "../../../modules/auth/selectors";

const Billing = () => {
  const { subscriptionPermissions } = AuthSelectors();
  const { subscription } = subscriptionPermissions;
  const isFreeTrial = subscription && subscription?.isFreeTrial;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IBilling[]>([]);
  const fetchBilling = async () => {
    setLoading(true);
    await GetBillings()
      .then((response) => setData(response.data))
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    let isMounted = false;
    if (!isMounted && !isFreeTrial) {
      fetchBilling();
    }
    return () => {
      isMounted = true;
    };
  }, []);

  return (
    <Section className="max-w-7xl mx-auto !mt-4 !p-4">
      <div className="flex flex-wrap justify-between bg-white z-10 pb-4 mb-4 border-b mt-8">
        <PageTitle
          title={"Billing"}
          subTitle={
            "Manage all things billing, including payment info and invoices"
          }
        />
      </div>
      <div className="space-y-4">
        {isFreeTrial ? (
          <ListWithAction
            rightNode={
              <Button
                text={"Upgrade plan"}
                onClick={() => navigate("/Dashboard/plans")}
                className="justify-center items-center !py-2 !max-md:px-5 !text-sm"
              />
            }
            leftNode={
              <>
                <span className="block text-sm font-bold leading-6">
                  Current plan
                </span>
                <span className="block text-sm font-medium leading-6 text-placeholder">
                  You are on the Free plan. Upgrade to unlock advanced features
                </span>
              </>
            }
          />
        ) : null}

        {loading ? (
          <Loading />
        ) : (
          <Invoices
            invoices={data}
            isNeedSubscription={subscription.canUpgrade}
          />
        )}

        {isFreeTrial ? null : (
          <ListWithAction
            rightNode={
              <Button
                text={"Cancel subscription"}
                variant="danger"
                onClick={() => console.log("cancel subscription")}
                className="justify-center items-center !py-2 !max-md:px-5 !text-sm"
              />
            }
            leftNode={
              <>
                <span className="block text-sm font-bold leading-6">
                  Payment Method
                </span>
                <span className="block text-sm font-medium leading-6 text-placeholder">
                  Renews on 15 January, 2024
                </span>
              </>
            }
          />
        )}

        <ListWithAction
          rightNode={
            <Button
              text={"Contact support"}
              variant="secondary"
              onClick={() => navigate("/Dashboard/support-tickets")}
              className="justify-center items-center !py-2 !max-md:px-5 !text-sm"
            />
          }
          leftNode={
            <>
              <span className="block text-sm font-bold leading-6">
                Need anything else?
              </span>
              <span className="block text-sm font-medium leading-6 text-placeholder">
                If you need further help with billing, our support team are here
                to help
              </span>
            </>
          }
        />
      </div>
    </Section>
  );
};

export default Billing;
