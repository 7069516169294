import { GetProp, TableProps } from "antd";
import { Dayjs } from "dayjs";

export interface InputFieldProps {
  label?: string;
  placeholder: string;
  name: string;
  required?: boolean;
  textArea?: boolean;
  type?: string;
  className?: string;
  component?: React.ReactNode;
  value?: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  error?: string;
  isReadOnly?: boolean;
  removeInputStyle?: boolean;
}
export type IBilling = {
  id: string;
  updatedAt: string;
  createdAt: string;
  createdById: string;
  createdBy: string;
  active: boolean;
  isPaid: boolean;
  documentNumber: string;
  price: number;
  documentURL: string;
};
export interface ISubscription {
  reports: {
    reports: string[];
    canExportToCSV: boolean;
  };
  searches: {
    searchesLeft?: number;
    maximumSearches?: number;
    searchesPercentageUsed?: number;
    searchesMade: number;
  };
  subscription: {
    isCancelled: boolean;
    isFreeTrial: boolean;
    lastChargeDate?: string;
    nextChargeDate?: string;
    canUpgrade: boolean;
  };
  users: {
    maximumUsers: number;
    usersCreated: number;
    usersLeft: number;
  };
  role: RoleType;
}
export enum SubscriptionType {
  MONTHLY,
  ANNUAL,
}
export enum RoleType {
  ADMIN = "Admin",
  MANAGER = "Manager",
  MEMBER = "Member",
}
export type EnumsType = {
  text: string;
  value: number | string;
};
export enum InviteStatus {
  INVITE_SENT,
  ACTIVE,
  NOT_ACTIVE,
}
export enum ProfileStatus {
  INVITE_SENT,
  PENDING,
  APPROVED,
  IGNORED,
}
export enum RequestMethod {
  GET = "GET",
  POST = "POST",
  DELETE = "DELETE",
  PUT = "PUT",
}
export type ITicket = {
  id?: string;
  updatedAt?: string;
  createdAt?: string;
  createdById?: string;
  createdBy?: string;
  active?: boolean;
  subject: string;
  description: string;
  requestType: string;
};
export type IDay = {
  label: string;
  value: string;
};
export type ResponseType<T> = {
  message: string;
  data: T;
  recordsTotal?: number;
  recordsFiltered?: number;
  draw?: number;
};
export interface IUserSettings {
  businessHoursFrom?: string;
  businessHoursTo?: string;
  monthlyReport?: boolean;
  weeklyReport?: boolean;
  promotionalNotifications?: boolean;
  offersNotifications?: boolean;
  workingDays: number[];
}
export interface UserType {
  userId: string;
  token: string;
  expiresAt: string;
  user: {
    id: string;
    picture: string;
    fullName: string;
    email: string;
    daysLeftForSubscription: number;
    needsToSubscribe: boolean;
    isAdmin: boolean;
    role: RoleType;
  };
}
export type TableResponseType<T> = {
  message: string;
  data: T;
  recordsTotal: number;
  recordsFiltered: number;
  draw: number;
};
export interface FAQS {
  question: string;
  answer: string;
  active: boolean;
}
export interface Pricing {
  id: string;
  updatedAt: string;
  createdAt: string;
  createdById: string;
  createdBy: string;
  active: boolean;
  isPopular: boolean;
  title: string;
  daysFree: number | null;
  order: number;
  pricingMonthly: number | null;
  priceYearly: number | null;
  isForEnterprise: boolean;
  features: string[];
  isOwn?: boolean;
  border?: string;
}
export interface DashboardPricing {
  plans: Pricing[];
  currentPlan: Pricing;
}
export type labelInValueType = {
  key?: string;
  value?: string;
  label?: string | number;
};
export type SelectionType = {
  id: string;
  name: string;
  isActive?: boolean;
};

export type ColumnsType<T> = TableProps<T>["columns"];
export type TablePaginationConfig = Exclude<
  GetProp<TableProps, "pagination">,
  boolean
>;
export interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  sorter?: Parameters<GetProp<TableProps, "onChange">>[2];
  filters?: Parameters<GetProp<TableProps, "onChange">>[1];
}
export enum TeamStatusEnum {
  Available,
  NotAvailable,
  Blocked,
}

export interface AllDTFilter {
  start: number;
  length: number;
  customSearch?: {
    from?: string | null;
    to?: string | null;
    searchTerm?: string;
    teamId?: string | null;
  };
  columns?: {
    data?: string;
    name?: string;
    searchable?: boolean;
    orderable?: boolean;
  }[];
  order?: { column: number; dir: string }[];
}
export interface Contact {
  name: string;
  subject: string;
  company: string;
  country: string;
  email: string;
  phoneNumber: string;
  info: string;
}

export interface ContactFormValues {
  fullName: string;
  company: string;
  email: string;
  telephone: string;
  country: string;
  subject: string;
  message: string;
  [key: string]: string | undefined;
}
export enum ProfileType {
  CUSTOMER,
  PROVIDER,
  SPAM,
}
export type IProfileType = {
  id: ProfileType;
  name: string;
};
export interface ProfileDT {
  id: string;
  index: string;
  name: string;
  email: string;
  type: IProfileType;
  createdAt: string;
  status: ProfileStatus;
  group: { title: string; color: string };
  active: boolean;
}
export interface TeamDT {
  id: string;
  updatedAt: string;
  createdAt: string;
  createdById: string;
  createdBy: string;
  active: boolean;
  name: string;
  department: string;
  teammatesCount: number;
  teammates: { id: string; name: string }[];
}
export interface TeammateDT {
  id: string;
  updatedAt: string;
  createdAt: string;
  createdById: string;
  createdBy: string;
  active: boolean;
  firstName: string;
  lastName: string;
  email: string;
  role: { name: string; id: string };
  picture: string;
  team: { name: string; id: string };
  status: { id: InviteStatus; name: string };
}

export interface PeopleForm {
  id: string;
  type: ProfileType;
  UpdateAllDomains: boolean;
}
export interface TeamForm {
  id: string;
  active: boolean;
  name: string;
  department: string;
}
export interface CreateTeamForm {
  name: string;
  department: string;
}

export enum AuthenticationType {
  GOOGLE,
  MICROSOFT,
}
export type CardContentFilter = {
  report: ReportTypeEnum;
  graphType: GraphTypeEnum;
  view: ViewEnum;
  flow: FlowEnum;
};
export type ICardContent = {
  title?: string;
  description?: string;
  tooltip?: string;
};
export enum ReportTypeEnum {
  STATISTICS,
  CONNECTIONS,
  ANALYTICS,
}
export enum ChartsTypeEnum {
  VOLUME,
  RESPOND_TIME_DISTRIBUTION,
  INVOLVEMENT_RECIPIENTS,
  CONNECTIVITY_SCOPE,
  COMMUNICATION,
  TOPICS,
  CONNECTIVITY_RANKS,
  CONNECTIVITY_ANALYSIS,
}
export enum GraphTypeEnum {
  VOLUME = "Volume",
  RESPOND_TIME_DISTRIBUTION = "Respond time distribution",
  INVOLVEMENT_RECIPIENTS = "Involvement recipients",
  CONNECTIVITY_SCOPE = "Connectivity scope",
  RADAR = "RADAR",
  SCOPE = "SCOPE",
}
export enum ViewEnum {
  ALL,
  INTERNAL,
  EXTERNAL,
}
export enum FlowEnum {
  ALL,
  INFLOW,
  OUTFLOW,
}
export interface OptionsFilterType {
  flow: FlowEnum;
  view: ViewEnum;
  report: ReportTypeEnum;
  user1?: string;
  user2?: string;
  dateRange: {
    from?: string | null;
    to?: string | null;
  };
  isSearch: boolean;
}
export enum PercentageEnum {
  STABLE,
  ASC,
  DESC,
}
export type StatProp = {
  title: string;
  info: {
    flow: FlowEnum;
    title: string;
    value: string;
    lastValue: string;
    percent: number;
    percentageType: PercentageEnum;
  };
};

export type IVolume = {
  date: string;
  currentPeriod: number;
  lastPeriod: number;
  yearlyAvg: number;
};
export type ICommunicationMetric = {
  parameter: string;
  currentPeriod: number;
  lastPeriod: number;
  yearlyAvg: number;
};
export type IInvolvement = {
  date: string;
  inflow: number;
  outflow: number;
  avg: number;
};
export type IScope = {
  id: string | number;
  title: string;
  parameter: string;
  total: number;
  percentage: number;
};
export type ITopics = {
  id: string | number;
  name: string;
  count: number;
  emails: string[];
};
export type IConnectivityRanks = {
  title: string;
  color: string;
  graph: {
    nodes: {
      id: number;
      label: string;
      title: string;
    }[];
    edges: {
      from: number;
      to: number;
    }[];
  };
};

export type IMessageStat = {
  value: string;
  percent: number;
  isDesc: boolean;
};
export type IConnectivityAnalysis = {
  index?: string;
  rank: string;
  name?: string;
  email: string;
  receive: IMessageStat;
  messages: IMessageStat;
  involvement: IMessageStat;
  sent: IMessageStat;
  responseTime: IMessageStat;
};
export type IError = {
  isOccurred: boolean;
  message?: string;
};
export type IChartDetails<T> = {
  loading: boolean;
  needSubscription: boolean;
  error: IError;
  data: T[];
};
export type ChartProp<T> = {
  [key in ChartsTypeEnum]?: IChartDetails<T>;
};
export type DateType = [
  start: Dayjs | null | undefined,
  end: Dayjs | null | undefined
];
// export type DateType2 = [Dayjs, Dayjs] | null;
