import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { cn } from "../utils/utils";
import { Tooltip } from "antd";
import { IoMdInformationCircleOutline } from "react-icons/io";
import NeedSubscription from "../pages/Dashboard/components/NeedSubscription";
import LoadingChart from "../pages/Dashboard/components/LoadingChart";
import { MdErrorOutline } from "react-icons/md";
import { IError } from "../utils/types";

const CardWrapper = ({
  className,
  innerClassName,
  content,
  title,
  description,
  tooltip,
  error,
  loading,
  needSubscription,
}: {
  className?: string;
  innerClassName?: string;
  content: React.ReactNode;
  title?: string;
  description?: string;
  tooltip?: string;
  error?: IError;
  loading?: boolean;
  needSubscription?: boolean;
}) => {
  const Header = () =>
    tooltip ? (
      <Tooltip
        color={"#fff"}
        overlayInnerStyle={{ color: "#000" }}
        placement="topLeft"
        arrow={false}
        title={tooltip}
        className="cursor-pointer flex gap-1 items-center"
      >
        <CardTitle>{title}</CardTitle>
        <IoMdInformationCircleOutline color={"#707070 "} />
      </Tooltip>
    ) : (
      <CardTitle>{title}</CardTitle>
    );
  return (
    <Card className={cn(className, "flex flex-col justify-between")}>
      <CardHeader>
        <Header />
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardContent className={cn(innerClassName, "max-md:px-2 relative")}>
        {!loading && !error?.isOccurred && needSubscription ? (
          <NeedSubscription title={title} />
        ) : null}
        {loading ? (
          <LoadingChart />
        ) : !(error && error.isOccurred) ? (
          content
        ) : null}
        {!loading && error && error.isOccurred ? (
          <div className="flex gap-2 items-center p-2 bg-gray-50 rounded-lg">
            <MdErrorOutline className="text-red-700" />
            <p className="text-red-700">{error?.message}</p>
          </div>
        ) : null}
      </CardContent>
    </Card>
  );
};

export default CardWrapper;
