import React from "react";
import { Badge } from "../../../../components/ui/badge";
import { Download } from "lucide-react";
import CardWrapper from "../../../../components/CardWrapper";
import { ScrollArea } from "../../../../components/ui/scroll-area";
import { IBilling } from "../../../../utils/types";
import Status from "./Status";
import dayjs from "dayjs";

const Invoices = ({
  invoices,
  isNeedSubscription,
}: {
  invoices: IBilling[];
  isNeedSubscription?: boolean;
}) => {
  return (
    <CardWrapper
      className="col-span-2"
      title={"Latest Billing"}
      description="Here is your recent invoices history"
      innerClassName="flex flex-1 min-h-[15rem]"
      needSubscription={isNeedSubscription}
      content={
        invoices.length === 0 ? (
          <div className="flex flex-1 py-4 items-center justify-center rounded-lg border border-dashed border-gray-300 shadow-sm">
            <div className="flex flex-col items-center gap-1 text-center">
              <h3 className="text-xl font-bold tracking-tight">
                No invoices available
              </h3>
              <p className="text-sm text-muted-foreground">
                You can start viewing as soon as you has a renew.
              </p>
            </div>
          </div>
        ) : (
          <div className="flex flex-1 flex-col gap-2">
            <ScrollArea className="h-0 min-h-[400px]">
              {invoices.map((invoice) => (
                <div
                  key={invoice.documentNumber}
                  className="border lg:p-4 max-md:py-2 rounded-md grid lg:grid-cols-4 items-end w-full"
                >
                  <h3 className="font-bold">{invoice.documentNumber}</h3>
                  <div className="flex gap-2 items-end">
                    {/* <span className="text-placeholder text-xs">
                    {invoice.paymentMethod}
                  </span> */}
                    <div>
                      {/* <Badge>{invoice.isPaid}</Badge> */}
                      <Status status={invoice.isPaid} small />
                    </div>
                  </div>
                  <span className="font-bold block max-md:text-sm">
                    ₪{invoice.price}
                  </span>
                  <div
                    className="flex gap-2 items-end lg:place-items-end cursor-pointer"
                    onClick={() => window.open(invoice.documentURL, "_blank")}
                  >
                    <span className="font-bold text-placeholder text-xs">
                      {invoice.createdAt
                        ? dayjs(invoice.createdAt).format("dd DD, MMM YYYY")
                        : ""}
                    </span>
                    <Download size={20} />
                  </div>
                </div>
              ))}
            </ScrollArea>
          </div>
        )
      }
    />
  );
};

export default Invoices;
