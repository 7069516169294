import React, { useEffect, useState } from "react";

const SIZE = 30;
const RatingEmoji = ({
  rating,
  onRate,
}: {
  rating: number;
  onRate: Function;
}) => {
  const [selectedRating, setSelectedRating] = useState(rating || -1);
  useEffect(() => {
    setSelectedRating(rating || -1);
  }, [rating]);

  const handleRatingClick = (rating: number) => {
    setSelectedRating(rating);
    onRate(rating);
  };

  const renderFace = (rating: number, expression: any) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
      width={SIZE}
      height={SIZE}
      onClick={() => handleRatingClick(rating)}
      style={{ cursor: "pointer" }}
    >
      <circle
        cx="40"
        cy="40"
        r="40"
        fill={rating <= selectedRating ? "#3b82f6" : "#d1d5db"}
      />
      <circle cx="30" cy="30" r="5" fill="#fff" />
      <circle cx="50" cy="30" r="5" fill="#fff" />
      {expression}
    </svg>
  );

  return (
    <div className="flex gap-2">
      {renderFace(
        1,
        <path
          d="M25 55 q15 -10 30 0"
          stroke="#fff"
          strokeWidth="4"
          fill="none"
        />
      )}
      {renderFace(
        2,
        <path
          d="M25 50 q15 -5 30 0"
          stroke="#fff"
          strokeWidth="4"
          fill="none"
        />
      )}
      {renderFace(
        3,
        <line x1="25" y1="50" x2="55" y2="50" stroke="#fff" strokeWidth="4" />
      )}
      {renderFace(
        4,
        <path
          d="M25 45 q15 10 30 0"
          stroke="#fff"
          strokeWidth="4"
          fill="none"
        />
      )}
      {renderFace(
        5,
        <path
          d="M25 40 q15 20 30 0"
          stroke="#fff"
          strokeWidth="4"
          fill="none"
        />
      )}
    </div>
  );
};

export default RatingEmoji;
