import { useCallback, useEffect, useState } from "react";
import { useAlertsContext } from "../../../../hooks/useAlertsContext";
import { GetSettings, UpdateSettings } from "../../../../modules/auth/service";
import { IDay, IUserSettings } from "../../../../utils/types";

const initialFilterOption = {
  businessHoursFrom: undefined,
  businessHoursTo: undefined,
  monthlyReport: false,
  weeklyReport: false,
  promotionalNotifications: false,
  offersNotifications: false,
  workingDays: [],
};
const daysOfWeek: IDay[] = [
  { label: "S", value: "Sunday" },
  { label: "M", value: "Monday" },
  { label: "T", value: "Tuesday" },
  { label: "W", value: "Wednesday" },
  { label: "T", value: "Thursday" },
  { label: "F", value: "Friday" },
  { label: "S", value: "Saturday" },
];
const useSettings = () => {
  const { toastError, toastSuccess } = useAlertsContext();
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [userSettings, setUserSettings] =
    useState<IUserSettings>(initialFilterOption);
  const getSettings = async () => {
    setFetching(true);
    await GetSettings()
      .then((response) => {
        setUserSettings(response.data);
      })
      .catch((err) => toastError(err?.data?.message || "Error Occurred"))
      .finally(() => setFetching(false));
  };
  const updateSettings = async () => {
    setLoading(true);
    await UpdateSettings(userSettings)
      .then((response) => {
        toastSuccess(response.message);
      })
      .catch((err) => toastError(err?.data?.message || "Error Occurred"))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getSettings();
  }, []);
  const handleForm = useCallback(
    (key: string, value: any) => {
      if (key === "hours") {
        const businessHoursFrom = value && value[0] ? value[0] : "";
        const businessHoursTo = value && value[1] ? value[1] : "";
        setUserSettings({
          ...userSettings,
          businessHoursFrom,
          businessHoursTo,
        });
      } else {
        setUserSettings({ ...userSettings, [key]: value });
      }
    },
    [userSettings]
  );

  return {
    fetching,
    loading,
    userSettings,
    daysOfWeek,
    updateSettings,
    handleForm,
  };
};
export default useSettings;
