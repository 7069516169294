import { useState } from "react";

import {
  CreateTeam,
  DeleteTeam,
  EditTeam,
  FetchInvite,
  GetTeamById,
  InviteTeammate,
  ResendInvitationTeammate,
  RevokeInvitationTeammate,
} from "../services/service";
import { useAlertsContext } from "../../../hooks/useAlertsContext";
import { CreateTeamForm, TeamDT, TeamForm } from "../../../utils/types";

const useActions = () => {
  const { toastError, toastSuccess } = useAlertsContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TeamDT | undefined>(undefined);

  const Create = async (form: CreateTeamForm, cb = () => {}) => {
    setLoading(true);
    await CreateTeam(form)
      .then((response) => {
        cb && cb();
      })
      .catch((e) => {
        if (e?.status && e?.status === 401) {
          toastError(e?.data?.message || "Token Expired");
          // TODO
          //   setTimeout(() => {
          //     dispatch(logout());
          //   }, 1500);
        } else {
          toastError(e?.data?.message || "Error Adding new team");
        }
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 5000);
      });
  };

  const Edit = async (form: TeamForm, cb = (close: boolean) => {}) => {
    setLoading(true);
    await EditTeam(form)
      .then((response) => {
        cb && cb(true);
        toastSuccess(response?.message);
      })
      .catch((error) => {
        cb && cb(false);
        toastError(error);
      })
      .finally(() => setLoading(false));
  };

  const Delete = async (id: string, cb = (close: boolean) => {}) => {
    setLoading(true);
    await DeleteTeam(id)
      .then((response) => {
        cb && cb(true);
        toastSuccess(response?.message);
      })
      .catch((error) => {
        cb && cb(false);
        toastError(error);
      })
      .finally(() => setLoading(false));
  };

  const GetById = async (id: string, cb = () => {}) => {
    setLoading(true);
    await GetTeamById(id)
      .then((response) => {
        setData(response.data);
        cb && cb();
      })
      .catch((error) => toastError(error))
      .finally(() => setLoading(false));
  };

  const InviteMember = async (
    email: string,
    teamId: string,
    cb = (error: boolean) => {}
  ) => {
    setLoading(true);
    await InviteTeammate(email)
      .then((response) => {
        cb && cb(true);
        toastSuccess(response?.message);
      })
      .catch((error) => {
        cb && cb(false);
        console.log({ error });
        toastError(error?.data?.message);
      })
      .finally(() => setLoading(false));
  };
  const ResendInvitation = async (id: string, cb = () => {}) => {
    setLoading(true);
    await ResendInvitationTeammate(id)
      .then((response) => {
        cb && cb();
        toastSuccess(response?.message);
      })
      .catch((error) => toastError(error?.data?.message))
      .finally(() => setLoading(false));
  };
  const RevokeInvitation = async (id: string, cb = () => {}) => {
    setLoading(true);
    await RevokeInvitationTeammate(id)
      .then((response) => {
        cb && cb();
        toastSuccess(response?.message);
      })
      .catch((error) => toastError(error?.data?.message))
      .finally(() => setLoading(false));
  };
  const AcceptInvite = async (id: string, cb = (msg?: string) => {}) => {
    setLoading(true);
    await FetchInvite(id)
      .then((response) => {
        cb && cb();
      })
      .catch((error) => {
        console.log({ error });
        cb && cb(error?.data?.message);
      })
      .finally(() => setLoading(false));
  };
  return {
    Delete,
    Create,
    Edit,
    GetById,
    data,
    loading,
    AcceptInvite,
    InviteMember,
    ResendInvitation,
    RevokeInvitation,
  };
};

export default useActions;
