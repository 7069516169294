import React from "react";
import { PiTrendUpBold } from "react-icons/pi";
import { cn } from "../../utils/utils";
import { PercentageEnum } from "../../utils/types";
import { TbArrowWaveRightUp } from "react-icons/tb";
const RenderPercentage = (
  icon: JSX.Element,
  colorClass: string,
  percent: number
) => (
  <div
    className={cn(
      "flex items-center gap-1 rounded-full text-center font-bold",
      colorClass
    )}
  >
    {icon}
    <span className="items-baseline tabular-nums">{percent}%</span>
  </div>
);

const percentageMap: Record<PercentageEnum, (percent: number) => JSX.Element> =
  {
    [PercentageEnum.ASC]: (percent: number) =>
      RenderPercentage(<PiTrendUpBold size={15} />, "text-green-700", percent),
    [PercentageEnum.DESC]: (percent: number) =>
      RenderPercentage(
        <PiTrendUpBold size={15} className="-rotate-180" />,
        "text-red-700",
        percent
      ),
    [PercentageEnum.STABLE]: (percent: number) =>
      RenderPercentage(
        <TbArrowWaveRightUp size={15} />,
        "text-gray-400",
        percent
      ),
  };

const StatValue = ({
  text,
  percentType,
  percent,
}: {
  text: string;
  percentType: PercentageEnum;
  percent: number;
}) => {
  return (
    <div className="flex gap-2 items-end">
      <span className="text-lg font-bold">{text}</span>
      {percentageMap[percentType](percent)}
    </div>
  );
};

export default StatValue;
