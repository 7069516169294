import { ITicket, RequestMethod, ResponseType } from "../../../../utils/types";
import { API_ENDPOINTS } from "../../../../utils/api-endpoints";
import BaseService from "../../../../services/BaseService";

export const GetReviews = async (): Promise<ResponseType<any>> =>
  await BaseService(API_ENDPOINTS.GET_REVIEWS, RequestMethod.GET);

export const AddReview = async (review: {
  value: number;
  type: string;
}): Promise<ResponseType<any>> =>
  await BaseService(API_ENDPOINTS.ADD_REVIEW, RequestMethod.POST, review);

export const GetTickets = async (): Promise<ResponseType<ITicket[]>> =>
  await BaseService(API_ENDPOINTS.GET_TICKETS, RequestMethod.GET);

export const AddTicket = async (ticket: ITicket): Promise<ResponseType<any>> =>
  await BaseService(API_ENDPOINTS.ADD_TICKET, RequestMethod.POST, ticket);
