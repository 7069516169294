import { useAppSelector } from "../../hooks/useStore";

const AuthSelectors = () => {
  const isLoading = useAppSelector((state) => state.auth?.isLoading);
  const isLoggedIn = useAppSelector((state) => state.auth?.isLoggedIn);
  const user = useAppSelector((state) => state.auth?.user);
  const subscriptionPermissions = useAppSelector(
    (state) => state.auth?.subscription
  );
  const isAdmin = useAppSelector((state) => state.auth?.user?.isAdmin);
  const role = useAppSelector((state) => state.auth?.user?.role);
  const token = useAppSelector((state) => state.auth?.token);
  const expiresAt = useAppSelector((state) => state.auth?.expiresAt);
  const authenticatedUsing = useAppSelector(
    (state) => state.auth?.authenticatedUsing
  );
  const state = useAppSelector((state) => state);
  return {
    isLoading,
    isLoggedIn,
    user,
    token,
    state,
    expiresAt,
    isAdmin,
    authenticatedUsing,
    role,
    subscriptionPermissions,
  };
};

export default AuthSelectors;
