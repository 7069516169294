import React, { useState } from "react";
import Modal from "../../../../components/Modal";
import Icon from "../../../../components/ui/Buttons/Icon";
import { IoCloseOutline } from "react-icons/io5";
import { Form, FormInstance, Input } from "antd";
import Button from "../../../../components/Button";
import { useParams } from "react-router-dom";
import { UserRoundPlus } from "lucide-react";

const InviteProfileModal = ({
  form,
  showModal,
  dismiss,
  submit,
  loading,
}: {
  form: FormInstance;
  showModal: boolean;
  dismiss: Function;
  submit: Function;
  loading: boolean;
}) => {
  const onFinish = (values: any) => {
    submit(values);
  };
  const values = Form.useWatch([], form);
  const [submittable, setSubmittable] = useState(false);
  React.useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);
  return (
    <Modal
      showModal={showModal}
      dismiss={dismiss}
      className="w-[30dvw] max-md:min-w-full"
    >
      {/*header*/}
      <div className="flex justify-between items-center">
        <h1 className="font-bold">Refer a friend</h1>
        <Icon className="!bg-transparent" onClick={() => dismiss()}>
          <IoCloseOutline color={"#000"} size={20} />
        </Icon>
      </div>
      <p className="text-placeholder leading-4 text-sm">
        We will send a confirmation email to each member you invite. Once they
        accept, you will be able to see their email statistics. If your business
        environment improves, you too, send invitations to acquaintances to use
        the application for free.
      </p>
      <div className="mt-8">
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            required
            name="email"
            initialValue={""}
            rules={[
              {
                type: "email",
                required: true,
                message: `Email is required!`,
              },
            ]}
          >
            <Input placeholder="example@company.com" className="py-2" />
          </Form.Item>

          <div className="flex gap-2 items-center justify-end mt-4 text-xl text-center whitespace-nowrap">
            <Button
              text={"Cancel"}
              disabled={loading}
              type={"button"}
              variant="secondary"
              onClick={() => dismiss()}
              className="justify-center items-center !py-2"
            />
            <Button
              text={"Send invite"}
              type={"submit"}
              variant={!submittable ? "disabled" : "primary"}
              loading={loading}
              className={`"justify-center items-center !px-12 !py-2 ${
                !submittable ? "!text-gray-400" : "text-white bg-primaryBlue"
              }   !max-md:px-5"`}
            />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

const InviteProfile = ({ fetchPeople }: { fetchPeople: Function }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  //   const { InviteProfile, loading } = useActions();
  const { teamId } = useParams();
  const [form] = Form.useForm();
  // TODO here
  const AddProfile = ({ email }: { email: string }) => {
    setIsSubmitting(true);
    console.log(email, teamId || "", (isOk: boolean) => {
      if (isOk) {
        fetchPeople();
        setModalVisible(false);
        form.resetFields();
      }
      setIsSubmitting(false);
    });
    setTimeout(() => {
      fetchPeople();
      setModalVisible(false);
      form.resetFields();
    }, 2000);
  };
  const DismissModal = () => {
    setModalVisible(false);
    form.resetFields();
  };
  return (
    <>
      <Button
        text="Refer a friend"
        onClick={() => setModalVisible(true)}
        variant="primary"
        className={`flex items-center gap-2 p-4 rounded-xl cursor-pointer shadow-sm h-full max-md:h-auto !my-0`}
        icon={<UserRoundPlus />}
      />
      {modalVisible ? (
        <InviteProfileModal
          form={form}
          showModal={modalVisible}
          loading={isSubmitting}
          //   loading={isSubmitting && !loading}
          dismiss={DismissModal}
          submit={AddProfile}
        />
      ) : null}
    </>
  );
};
export default InviteProfile;
