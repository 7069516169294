import React from "react";
import PageTitle from "../../components/PageTitle";
import TeammatesTable from "./Teammate/components/TeammatesTable";
import Section from "../../layouts/Section";

const Insiders = () => {
  return (
    <Section className="max-w-7xl mx-auto !mt-4 !p-4">
      <div className="flex flex-wrap justify-between bg-white z-10 pb-4 mb-4 border-b mt-8">
        <PageTitle
          title={"User Management"}
          subTitle={"Manage your members and their mailboxes"}
        />
      </div>
      <TeammatesTable />
    </Section>
  );
};

export default Insiders;
