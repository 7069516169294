import React from "react";
import { ProfileDT } from "../../../../utils/types";
import EditProfile from "./EditProfile";
import DeleteProfile from "./DeleteProfile";

const Actions = ({
  profile,
  fetchProfiles,
}: {
  profile: ProfileDT;
  fetchProfiles: Function;
}) => {
  return (
    <div className="flex gap-2">
      <EditProfile profile={profile} fetchProfiles={fetchProfiles} />
      <DeleteProfile teamId={profile.id} fetchTeams={fetchProfiles} />
    </div>
  );
};

export default Actions;
