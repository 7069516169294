import Section from "../../../layouts/Section";
import AuthSelectors from "../../../modules/auth/selectors";
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import ProfilesTable from "./components/ProfilesTable";
import useActions from "./hooks/useActions";
import TableHeader from "../../../components/ui/TableHeader";
import InviteProfile from "./components/InviteProfile";

const People = () => {
  const { isAdmin } = AuthSelectors();
  const {
    FetchPeople,
    searchTerm,
    tableParams,
    handleSearch,
    handleTableChange,
    handlePaginationChange,
    data,
    loading,
  } = useActions();
  useEffect(() => {
    FetchPeople();
  }, [JSON.stringify(tableParams), searchTerm]);

  return isAdmin ? (
    <Section className="max-w-7xl mx-auto !mt-4 !p-4 bg-white">
      <TableHeader
        searchTerm={searchTerm}
        placeholder={"Search by name, email..."}
        handler={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleSearch(e.target.value)
        }
        leftNode={<InviteProfile fetchPeople={FetchPeople} />}
      />
      <ProfilesTable
        data={data}
        loading={loading}
        tableParams={tableParams}
        handleTableChange={handleTableChange}
        handlePaginationChange={handlePaginationChange}
        fetchPeople={FetchPeople}
      />
    </Section>
  ) : (
    <Navigate to={"/Dashboard"} />
  );
};

export default People;
