import React, { useState } from "react";
import Modal from "../../../../components/Modal";
import { IoCloseOutline } from "react-icons/io5";
import Icon from "../../../../components/ui/Buttons/Icon";
import Button from "../../../../components/Button";
import { Checkbox, Form, Select, SelectProps, Tooltip } from "antd";
import useActions from "../hooks/useActions";
import { PeopleForm, ProfileDT, ProfileType } from "../../../../utils/types";
import { FiEdit } from "react-icons/fi";
const typeOptions: SelectProps["options"] = [
  {
    label: "Customer",
    value: ProfileType.CUSTOMER,
  },
  {
    label: "Provider",
    value: ProfileType.PROVIDER,
  },
  {
    label: "Spam",
    value: ProfileType.SPAM,
  },
];
const EditProfileModal = ({
  showModal,
  dismiss,
  submit,
  loading,
  profile,
}: {
  showModal: boolean;
  dismiss: Function;
  submit: Function;
  loading: boolean;
  profile: ProfileDT;
}) => {
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    submit(values);
    form.resetFields();
  };
  const onDismiss = () => {
    dismiss();
    form.resetFields();
  };

  return (
    <Modal
      showModal={showModal}
      dismiss={() => onDismiss()}
      className="w-[30dvw] max-md:min-w-full"
    >
      {/*header*/}
      <div className="flex justify-between items-center">
        <h1 className="font-bold">{"Edit Profile"}</h1>
        <Icon className="!bg-transparent" onClick={() => onDismiss()}>
          <IoCloseOutline color={"#000"} size={20} />
        </Icon>
      </div>
      <p className="text-placeholder leading-4 text-sm">
        Customize and change the profile's information.
      </p>
      <div className="mt-8">
        <Form
          form={form}
          initialValues={{
            id: profile?.id,
            type: profile?.type?.id,
          }}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item required hidden name="id" />
          <Form.Item
            className="mt-2"
            required
            name="type"
            label="Type"
            labelAlign="left"
            initialValue={undefined}
            rules={[
              {
                required: true,
                message: `type is required!`,
              },
            ]}
          >
            <Select
              // showSearch
              style={{ width: "100%" }}
              placeholder={"Select type"}
              // value={undefined}
              // onChange={(value) => console.log("type", value)}
              options={typeOptions}
              className={"h-[2.5rem]"}
              size={"large"}
              allowClear={false}
            />
          </Form.Item>
          {/* <Form.Item
            label="Status"
            labelAlign="left"
            valuePropName="checked"
            name="active"
          >
            <Switch />
          </Form.Item> */}
          <Form.Item
            label="Update"
            labelAlign="left"
            valuePropName="checked"
            name="UpdateAllDomains"
          >
            <Checkbox>Update All Domains</Checkbox>
          </Form.Item>
          <div className="flex gap-2 items-center justify-end mt-4 text-xl text-center whitespace-nowrap">
            <Button
              text={"Cancel"}
              disabled={loading}
              variant="secondary"
              onClick={() => onDismiss()}
              className="justify-center items-center !py-2"
            />
            <Button
              text={"Update"}
              type={"submit"}
              onSubmit={() => onFinish(form.getFieldsValue())}
              loading={loading}
              className="justify-center items-center !px-12 !py-2 text-white bg-primaryBlue !max-md:px-5"
            />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

const EditProfile = ({
  fetchProfiles,
  profile,
}: {
  fetchProfiles: Function;
  profile: ProfileDT;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { Edit } = useActions();
  const UpdateProfile = (form: PeopleForm) => {
    setIsSubmitting(true);
    Edit(form, (close) => {
      fetchProfiles();
      setIsSubmitting(false);
      if (close) {
        setModalVisible(false);
      }
    });
  };
  return (
    <>
      <Tooltip title="Edit Profile">
        <div>
          <Icon
            onClick={(e) => {
              e?.stopPropagation();
              setModalVisible(true);
            }}
          >
            <FiEdit />
          </Icon>
        </div>
      </Tooltip>
      {modalVisible ? (
        <EditProfileModal
          profile={profile}
          showModal={modalVisible}
          loading={isSubmitting}
          dismiss={() => {
            setModalVisible(false);
          }}
          submit={UpdateProfile}
        />
      ) : null}
    </>
  );
};

export default EditProfile;
