import React from "react";
import Section from "../../layouts/Section";
import images from "../../data/images";
import Button from "../../components/Button";
import Ripple from "../../components/Ripple";

const Newsletter = () => {
  return (
    <Section
      title="Get instant access"
      className="relative overflow-clip"
      placeholder="Lead your team with passion and empathy, focus on what matters, and we will help you understand what management means."
    >
      <section className="relative flex flex-col justify-center items-center px-20 mt-8 w-full max-md:px-5 max-md:mt-4">
        <div className="flex max-md:flex-col justify-center gap-x-2 items-center lg:w-[70%] w-full z-10">
          <input
            type="text"
            placeholder="Enter your email"
            className="focus:outline-none bg-gray-100 px-4 py-3 rounded-lg lg:w-[30%] w-full"
          />
          <Button
            text={"Get access"}
            onClick={() => console.log("/Newsletter")}
            className="!border-none hover:bg-white max-md:w-full"
          />
        </div>
      </section>
      <div className="absolute lg:-bottom-1/2 -bottom-[60%] -z-10 flex h-[500px] w-full flex-col items-center justify-center overflow-hidden bg-background md:shadow-xl">
        <img
          loading="lazy"
          src={images.LogoBadge}
          alt="InterSight Company Logo"
          className={"w-[8rem] lg:w-[12rem]"}
        />
        <Ripple />
      </div>
    </Section>
  );
};

export default Newsletter;
