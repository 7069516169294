import React, { useEffect, useState } from "react";
import Section from "../layouts/Section";
import images from "../data/images";
import { Link, useNavigate, useParams } from "react-router-dom";
import useActions from "./Team/hooks/useActions";
import SEO from "../layouts/SEO";
import Button from "../components/Button";
import Icon from "../components/ui/Buttons/Icon";
import { GrHome } from "react-icons/gr";
import ArrowIcon from "../components/ui/Buttons/ArrowIcon";
import { LuLogIn } from "react-icons/lu";

const AcceptInvitation = () => {
  const { invitationId } = useParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const { AcceptInvite } = useActions();
  useEffect(() => {
    AcceptInvite(invitationId || "", (msg) => {
      if (msg) {
        setErrorMessage(msg);
      } else {
        navigate("/get-started/Login", { replace: true });
      }
    });

    return () => {
      setErrorMessage("");
    };
  }, []);
  return (
    <Section id="notfound">
      <SEO title={"Accept Invitation | InterSight"} />
      <div className="flex flex-col px-8 lg:px-20 items-center justify-center">
        <img
          loading="lazy"
          src={images.logoPrimary}
          className="w-[20rem] object-contain"
          alt="InterSight logo"
        />
        {/* <!-- Error Container --> */}
        <div className="flex flex-col items-center text-center">
          <div className="font-bold text-3xl lg:text-6xl md:text-5xl mt-10">
            Accept Invitation
          </div>
          <div className="text-gray-400 font-medium text-sm md:text-xl lg:text-2xl mt-8">
            {errorMessage}
          </div>
          <Button
            text={"Go Back"}
            onClick={() => navigate(-1)}
            className="justify-center items-center !px-12 !py-3 mt-8 !font-black text-white bg-primaryBlue !max-md:px-5"
          />
        </div>
        {/* <!-- Continue With --> */}
        <div className="flex flex-col mt-24 w-full lg:w-[80%]">
          <div className="text-gray-400 font-bold uppercase">Continue With</div>
          <div className="flex flex-col items-stretch mt-5">
            {/* <!-- Nav Item #1 --> */}
            <Link
              to="/"
              replace={true}
              className="flex flex-row group px-4 py-8 border-t hover:cursor-pointer transition-all duration-200 delay-100"
            >
              {/* <!-- Nav Icon --> */}
              <Icon>
                <GrHome />
              </Icon>
              {/* <!-- Text --> */}
              <div className="grow flex flex-col pl-5">
                <div className="font-bold text-sm md:text-lg lg:text-xl group-hover:underline">
                  Home Page
                </div>
                <div className="font-semibold text-sm md:text-md lg:text-lg text-gray-400 group-hover:text-gray-500 transition-all duration-200 delay-100">
                  Everything starts here
                </div>
              </div>
              {/* <!-- Chevron --> */}
              <ArrowIcon />
            </Link>
            {/* <!-- Nav Item #3 --> */}
            <Link
              to="/get-started/Login"
              replace={true}
              className="flex flex-row group px-4 py-8 border-t hover:cursor-pointer transition-all duration-200 delay-100"
            >
              {/* <!-- Nav Icon --> */}
              <Icon>
                <LuLogIn />
              </Icon>
              {/* <!-- Text --> */}
              <div className="grow flex flex-col pl-5">
                <div className="font-bold text-sm md:text-lg lg:text-xl group-hover:underline">
                  Connect
                </div>
                <div className="font-semibold text-sm md:text-md lg:text-lg text-gray-400 group-hover:text-gray-500 transition-all duration-200 delay-100">
                  Connect to your account
                </div>
              </div>
              {/* <!-- Chevron --> */}
              <ArrowIcon />
            </Link>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default AcceptInvitation;
