import React from "react";
import Section from "../../layouts/Section";
import Button from "../../components/Button";
import { Link, Navigate, useLocation } from "react-router-dom";
import images from "../../data/images";
import { BsBarChartFill } from "react-icons/bs";
import { MdPeople } from "react-icons/md";
import { HiOutlineBars3BottomLeft } from "react-icons/hi2";
import { FaUnlockAlt } from "react-icons/fa";
import AuthActions from "../../modules/auth/actions";
import AuthSelectors from "../../modules/auth/selectors";
import Planet from "../../components/Planet";
import MicrosoftIcon from "../../data/MicrosoftIcon";
import GoogleIcon from "../../data/GoogleIcon";

const Signup = () => {
  const { googleLoginAction, msLoginAction } = AuthActions();
  const { isLoggedIn, token } = AuthSelectors();
  const location = useLocation();
  return isLoggedIn && token ? (
    <Navigate to="/Dashboard" replace state={{ from: location }} />
  ) : (
    <Section id="SignUp" className="!p-0 !m-0 ">
      <div className="grid grid-cols-4 min-h-[92dvh]">
        <div className="h-full text-sm col-span-2 max-md:order-last max-md:col-span-4 rounded-tr-xl bg-primaryBlue/5 lg:px-12 px-4 flex flex-col justify-center">
          <Planet />
          <div className="flex gap-4 p-4 w-full">
            <BsBarChartFill size={35} />
            <div className="flex flex-col">
              <span className="font-bold">Wide range of email metrics</span>
              <span className="text-heading">
                Get detailed analytics and insights on all email activity,
                including response times, email volumes and much more
              </span>
            </div>
          </div>
          <div className="flex gap-4 p-4 w-full">
            <MdPeople size={35} />
            <div className="flex flex-col">
              <span className="font-bold">
                Understand your team’s performance
              </span>
              <span className="text-heading">
                Get real data on how your team members are performing and start
                guiding them based on real insights, not guesswork
              </span>
            </div>
          </div>
          <div className="flex gap-4 p-4 w-full">
            <HiOutlineBars3BottomLeft size={35} />
            <div className="flex flex-col">
              <span className="font-bold">Access limited to email headers</span>
              <span className="text-heading">
                We don’t read or store the content or attachments of your
                emails. We only use email metadata to generate your statistics
              </span>
            </div>
          </div>
          <div className="flex gap-4 p-4 w-full">
            <FaUnlockAlt size={25} />
            <div className="flex flex-col">
              <span className="font-bold">Secure and compliant</span>
              <span className="text-heading">
                InterSight is ISO 27001 certified, GDPR compliant and conducts
                regular third-party audits and pen testing
              </span>
            </div>
          </div>
          <div className="mt-8 flex gap-4 p-4 w-full">
            <div className="flex flex-col">
              <span className="text-heading">
                Trusted by teams at leading companies
              </span>
              <div className="flex gap-4 mt-4">
                <img
                  loading="lazy"
                  src={images.google}
                  alt={"Google logo"}
                  className={"shrink-0 max-w-full h-[2rem]"}
                />
                <img
                  loading="lazy"
                  src={images.microsoft}
                  alt={"microsoft logo"}
                  className={"shrink-0 max-w-full h-[2rem]"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-2 max-md:col-span-4 flex flex-col justify-center items-center px-6 py-12 lg:px-8 h-full">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h2 className="mt-12 text-center text-2xl font-bold leading-9 tracking-tight">
              Create a free account
            </h2>
            <div className="flex max-md:flex-col gap-2 items-center justify-center text-center">
              <span className="text-placeholder">
                {"Already have an InterSight account?"}
              </span>
              <Link
                to="/get-started/Login"
                className="text-primaryBlue font-bold"
              >
                {"Log in"}
              </Link>
            </div>
          </div>
          <div className="sm:mx-auto sm:w-full sm:max-w-sm my-4">
            <Button
              text={"Continue with Google"}
              variant="secondary"
              icon={<GoogleIcon />}
              onClick={() => googleLoginAction()}
              className="justify-center items-center !px-4 !lg:px-12 !py-3 w-full !max-md:px-5"
            />
            <Button
              text={"Continue with Microsoft"}
              variant="secondary"
              className="justify-center items-center !px-4 !lg:px-12 !py-3 w-full !max-md:px-5"
              onClick={() => msLoginAction()}
              icon={<MicrosoftIcon />}
            />
          </div>
          <span className="sm:mx-auto sm:w-full sm:max-w-sm text-placeholder text-center text-sm">
            {
              "By creating an account, I declare that I have read and accepted InterSight’s"
            }
            <Link to="/terms-of-service" className="font-bold mx-1">
              {"Terms and Conditions"}
            </Link>
            and
            <Link to="/privacy-policy" className="font-bold ml-1">
              {"Privacy Policy."}
            </Link>
          </span>
        </div>
      </div>
    </Section>
  );
};

export default Signup;
