import React, { useState } from "react";
import PageTitle from "../../../components/PageTitle";
import Section from "../../../layouts/Section";
import { Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import CardWrapper from "../../../components/CardWrapper";
import { ScrollArea } from "../../../components/ui/scroll-area";
import Button from "../../../components/Button";
import RatingEmoji from "../../../components/Rating";
import useTickets from "./hooks/useTickets";
import { ITicket } from "../../../utils/types";

const requestTypes = [
  { label: "Feedback", value: "Feedback" },
  { label: "Customer service", value: "Customer service" },
  {
    label: "Payments & Billing",
    value: "Payments & Billing",
  },
  {
    label: "Product development",
    value: "Product development",
  },
  {
    label: "Business opportunity",
    value: "Business opportunity",
  },
  { label: "Other", value: "Other" },
];
const Tickets = () => {
  const { loading, addReview, addTicket, reviews, tickets } = useTickets();
  const [newTicket, setNewTicket] = useState<ITicket>({
    subject: "",
    description: "",
    requestType: "",
  });
  // Function to handle creating a new ticket
  const handleCreateTicket = () => {
    if (newTicket.subject && newTicket.requestType) {
      addTicket(newTicket);
      setNewTicket({ subject: "", description: "", requestType: "" });
    }
  };

  return (
    <Section className="max-w-7xl mx-auto !mt-4 !p-4">
      <div className="flex flex-wrap justify-between bg-white z-10 pb-4 mb-4 border-b mt-8">
        <PageTitle
          title={"Support Tickets"}
          subTitle={"When customers have problems, they open support tickets."}
        />
      </div>
      <div className="grid grid-cols-3 gap-8">
        <CardWrapper
          className="col-span-1 !justify-start"
          title={"Create New Ticket"}
          description={
            "Fill up all the information here, then click submit button"
          }
          content={
            <div className="flex flex-col gap-4">
              <Select
                showSearch
                placeholder={"Select request type"}
                onChange={(option: any) =>
                  setNewTicket({ ...newTicket, requestType: option })
                }
                options={requestTypes}
                size={"large"}
                className={"!h-[2.5rem]"}
              />
              <Input
                placeholder="Subject"
                value={newTicket?.subject}
                onChange={(e: any) =>
                  setNewTicket({ ...newTicket, subject: e.target.value })
                }
              />
              <TextArea
                placeholder="Description"
                value={newTicket?.description}
                onChange={(e: any) =>
                  setNewTicket({ ...newTicket, description: e.target.value })
                }
              />
              <Button
                onClick={handleCreateTicket}
                className="w-fit !self-start"
                text="Submit Ticket"
                loading={loading}
              />
            </div>
          }
        />

        {/* Tickets List */}
        <CardWrapper
          className="col-span-2  max-md:col-span-3 !justify-start"
          title={"Latest Support History"}
          description="Here is your most recent history"
          content={
            <ScrollArea className="h-0 min-h-[300px] gap-2">
              {tickets.length === 0 ? (
                <div className="flex flex-1 py-4 items-center justify-center rounded-lg border border-dashed border-gray-300 shadow-sm">
                  <div className="flex flex-col items-center gap-1 text-center">
                    <h3 className="text-xl font-bold tracking-tight">
                      No tickets available
                    </h3>
                    <p className="text-sm text-muted-foreground">
                      You can start see as soon as you add a new ticket.
                    </p>
                  </div>
                </div>
              ) : (
                tickets.map((ticket) => (
                  <div
                    key={ticket.id}
                    className="border p-4 rounded-md flex justify-between gap-2 mb-2"
                  >
                    <div className="w-full">
                      <h3 className="font-bold">{ticket.subject}</h3>
                      <div className="flex justify-between w-full">
                        {/* <Badge
                        // colorScheme={ticket.status === "open" ? "green" : "red"}
                        >
                          {ticket.status}
                        </Badge> */}
                        <span className="text-placeholder text-xs">
                          12\09\2024
                        </span>
                      </div>
                      <p>{ticket.description}</p>
                    </div>
                    {/* {ticket.status === "open" && (
                      <Button
                        onClick={() => handleCloseTicket(ticket.id)}
                        text="Cancel"
                        className="text-xs !p-1 !self-start !m-0"
                      />
                    )} */}
                  </div>
                ))
              )}
            </ScrollArea>
          }
        />
      </div>
      <div className="grid grid-cols-4 gap-8 mt-4">
        <CardWrapper
          className="col-span-2"
          title="Customer Service"
          description="We'd like to know how we can serve you better and how we do so far. Customer service is our top priority, Your feedback helps us stay on point!"
          content={
            <div className="flex flex-col gap-4">
              <ul className="text-xs space-y-1">
                <li>
                  <span className="font-bold mr-1">Responsiveness:</span>How
                  quickly and efficiently your inquiries or issues were
                  addressed.
                </li>
                <li>
                  <span className="font-bold mr-1">Clarity:</span>How clear and
                  helpful the information provided was.
                </li>
                <li>
                  <span className="font-bold mr-1">Professionalism:</span>The
                  friendliness, respect, and courtesy of our team.
                </li>
                <li>
                  <span className="font-bold mr-1">Problem-Solving</span>
                  How effectively we resolved your concerns or provided
                  solutions.
                </li>
                <li>
                  <span className="font-bold mr-1">Follow-Up:</span>
                  Whether you felt supported and valued throughout the process.
                </li>
              </ul>
              <RatingEmoji
                rating={reviews.customerService}
                onRate={(value: number) => addReview(value, "CustomerService")}
              />
            </div>
          }
        />
        <CardWrapper
          className="col-span-2"
          title="User Experience"
          description="We're excited to hear how the system is working out for you and what we can do to make it even better. Give us a quick rating or open a ticket for specifics to let us know how we're doing!"
          content={
            <div className="flex flex-col gap-4">
              <ul className="text-xs space-y-1">
                <li>
                  <span className="font-bold mr-1">Onboarding:</span>How smooth
                  and intuitive your initial setup and introduction to the app
                  felt.
                </li>
                <li>
                  <span className="font-bold mr-1">Ease of Use:</span>The
                  simplicity and clarity of navigating and completing tasks.
                </li>
                <li>
                  <span className="font-bold mr-1">Performance:</span>The app's
                  speed, reliability, and responsiveness.
                </li>
                <li>
                  <span className="font-bold mr-1">
                    Design and Accessibility:
                  </span>
                  The appeal and functionality of the interface across devices.
                </li>
                <li>
                  <span className="font-bold mr-1">Feature Relevance:</span>
                  How well the tools and features align with your needs and
                  goals.
                </li>
              </ul>
              <RatingEmoji
                rating={reviews.userExperience}
                onRate={(value: number) => addReview(value, "UserExperience")}
              />
            </div>
          }
        />
      </div>
    </Section>
  );
};

export default Tickets;
