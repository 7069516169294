import React from "react";
import Section from "../../layouts/Section";
import { SightsOutputs } from "./Sights/SightsOutputs";
import Beams from "./Sights/Beams";

// type ToolCardProps = {
//   icon: ReactNode;
//   title: string;
//   description: string;
//   className: string;
// };

// const ToolCard: React.FC<ToolCardProps> = ({
//   icon,
//   title,
//   description,
//   className,
// }) => (
//   <article
//     className={`group border relative col-span-3 flex flex-col justify-between overflow-hidden rounded-xl
//     bg-gray-50   transform-gpu  ${className}  `}
//   >
//     <div className="pointer-events-none z-10 flex transform-gpu flex-col gap-1 p-6 transition-all duration-300">
//       <div className="h-12 w-12 origin-left transform-gpu text-neutral-700 transition-all duration-300 ease-in-out group-hover:scale-75">
//         {icon}
//       </div>
//       <h3 className="text-xl font-semibold text-neutral-700 dark:text-neutral-300">
//         {title}
//       </h3>
//       <p className="max-w-lg leading-5 text-neutral-400">{description}</p>
//     </div>
//     {/* <div
//       className={`pointer-events-none absolute bottom-0 flex w-full translate-y-10 transform-gpu flex-row items-center p-4 opacity-0 transition-all duration-300 group-hover:translate-y-0 group-hover:opacity-100`}
//     >
//       <span>
//         Learn more
//         <BsArrowRight className="ml-2 h-4 w-4" />
//       </span>
//     </div> */}
//     <div className="pointer-events-none absolute inset-0 transform-gpu transition-all duration-300 group-hover:bg-black/[.03] group-hover:dark:bg-neutral-800/10" />
//   </article>
// );

// const tools = [
//   {
//     index: 1,
//     Icon: BsIntersect,
//     name: "Integration ecosystem",
//     description:
//       "Enhance your productivity by connecting with your favorite tools, keeping all your essentials in one place.",
//     className: "col-span-3 lg:col-span-1",
//   },
//   {
//     index: 2,
//     Icon: MdTrackChanges,
//     name: "Goal setting and tracking",
//     description:
//       "Define and track your goals, breaking down objectives into achievable tasks to keep your targets in sight.",
//     className: "col-span-3 lg:col-span-1",
//   },
//   {
//     index: 3,
//     Icon: BsShieldLock,
//     name: "Secure data encryption",
//     description:
//       "With end-to-end encryption, your data is securely stored and protected from unauthorized access.",
//     className: "col-span-3 lg:col-span-1",
//   },
// ];
const Tools = () => {
  return (
    <Section
      className="box !pb-0"
      badge="Email Analytics"
      title="All-in-one analytics for Individuals & Teams"
      placeholder="Foster business understanding in real-time, visualize data, develop speed, explore key metrics, and get insights."
    >
      <section className="flex-wrap justify-center px-5 lg:px-20 mt-10 lg:mt-16 w-full">
        <Beams />
        <SightsOutputs />
        {/* <BentoGrid className="auto-rows-auto">
          <div className="col-span-3 lg:col-span-3">
            <SightsOutputs />
          </div>
          {tools.map((tool, idx) => (
            <BentoCard key={idx} {...tool} />
          ))}
        </BentoGrid> */}
      </section>
    </Section>
  );
};

export default Tools;
