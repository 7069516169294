import BaseService from "../../../services/BaseService";
import { API_ENDPOINTS } from "../../../utils/api-endpoints";
import {
  ICommunicationMetric,
  IConnectivityAnalysis,
  IConnectivityRanks,
  IScope,
  ITopics,
  IVolume,
  OptionsFilterType,
  RequestMethod,
  ResponseType,
  StatProp,
} from "../../../utils/types";

export const GetStatistics = async (
  filters: OptionsFilterType
): Promise<ResponseType<StatProp[]>> =>
  await BaseService(API_ENDPOINTS.GET_STATISTICS, RequestMethod.POST, filters);

export const GetTopicsMenu = async (
  filters: OptionsFilterType
): Promise<ResponseType<ITopics[]>> =>
  await BaseService(API_ENDPOINTS.GET_TOPICS_MENU, RequestMethod.POST, filters);
export const GetMessagesVolume = async (
  filters: OptionsFilterType
): Promise<ResponseType<IVolume[]>> =>
  await BaseService(
    API_ENDPOINTS.GET_MESSAGES_VOLUME,
    RequestMethod.POST,
    filters
  );
export const GetInvolvementRecipients = async (
  filters: OptionsFilterType
): Promise<ResponseType<IVolume[]>> =>
  await BaseService(
    API_ENDPOINTS.GET_INVOLVEMENT_RECIPIENTS,
    RequestMethod.POST,
    filters
  );
export const GetCommunicationMetrics = async (
  filters: OptionsFilterType
): Promise<ResponseType<ICommunicationMetric[]>> =>
  await BaseService(
    API_ENDPOINTS.GET_COMMUNICATION_METRICS,
    RequestMethod.POST,
    filters
  );
export const GetRespondTimeDistribution = async (
  filters: OptionsFilterType
): Promise<ResponseType<IVolume[]>> =>
  await BaseService(
    API_ENDPOINTS.GET_RESPOND_TIME_DISTRIBUTION,
    RequestMethod.POST,
    filters
  );
export const GetConnectivityRanks = async (
  filters: OptionsFilterType
): Promise<ResponseType<IConnectivityRanks[]>> =>
  await BaseService(
    API_ENDPOINTS.GET_CONNECTIVITY_RANKS,
    RequestMethod.POST,
    filters
  );
export const GetConnectionsAnalysis = async (
  filters: OptionsFilterType
): Promise<ResponseType<IConnectivityAnalysis[]>> =>
  await BaseService(
    API_ENDPOINTS.GET_CONNECTIVITY_ANALYSIS,
    RequestMethod.POST,
    filters
  );
export const GetConnectivityScope = async (
  filters: OptionsFilterType
): Promise<ResponseType<IScope[]>> =>
  await BaseService(
    API_ENDPOINTS.GET_CONNECTIVITY_SCOPE,
    RequestMethod.POST,
    filters
  );

export const DownloadCSVReport = async (filters: OptionsFilterType) =>
  await BaseService(
    API_ENDPOINTS.REPORT_EXPORT,
    RequestMethod.POST,
    filters,
    null,
    undefined,
    "blob",
    true
  );
