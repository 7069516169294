import * as Actions from "./types";

const initialState = {
  user: {},
  token: null,
  expiresAt: null,
  isLoading: false,
  error: null,
  isLoggedIn: false,
  authenticatedUsing: "",
  subscription: {},
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload?.token,
        expiresAt: action.payload?.expiresAt,
        isLoading: false,
        error: null,
        isLoggedIn: true,
      };
    case Actions.SIGN_IN_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case Actions.SIGN_IN_SUCCESS: {
      const { token, expiresAt, user } = action.payload.data;
      const authenticatedUsing = action.payload.authenticatedUsing;
      return {
        ...state,
        token,
        expiresAt,
        user,
        error: null,
        isLoading: false,
        isLoggedIn: true,
        authenticatedUsing,
      };
    }
    case Actions.FETCH_PROFILE_SUCCESS: {
      const user = action.payload;
      return {
        ...state,
        user,
      };
    }
    case Actions.FETCH_SUBSCRIPTION_SUCCESS: {
      const subscription = action.payload;
      return {
        ...state,
        subscription,
      };
    }
    case Actions.SIGN_IN_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case Actions.LOGOUT_SUCCESS:
      return {
        ...initialState,
        isLoggedIn: false,
        authenticatedUsing: "",
      };
    case Actions.GET_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case Actions.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload?.user,
        isLoading: false,
        error: null,
      };
    case Actions.GET_USER_ERROR:
      return {
        ...state,
        error: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default authReducer;
