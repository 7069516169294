import React from "react";
import Chart from "./components/Chart";
import { ChartsTypeEnum } from "../../../../utils/types";
import Table from "./components/Table";

const Connections = (props: any) => {
  const { flow, charts } = props || {};
  const chartData = charts[ChartsTypeEnum.CONNECTIVITY_RANKS];
  const listData = charts[ChartsTypeEnum.CONNECTIVITY_ANALYSIS];

  return (
    <div className="flex flex-col gap-4 md:gap-8 mt-4 md:mt-8">
      <Chart flow={flow} details={chartData} />
      {/* <ConnectionsList flow={flow} details={listData} /> */}
      <Table list={listData} flow={flow} />

      {/* <div className="grid gap-4 md:grid-cols-1 md:gap-8 lg:grid-cols-3 auto-rows-auto">
        <ParticipantsGraph />
        <div className="col-span-2">
          <Table
            data={dataSource}
            loading={false}
            tableParams={undefined}
            handleTableChange={console.log}
            handlePaginationChange={console.log}
            fetchTeams={console.log}
          />
        </div>
      </div> */}
    </div>
  );
};

export default Connections;
