import React from "react";
import Section from "../../layouts/Section";
import Filters from "./components/Filters";
import useActions from "./hooks/useActions";
import Report from "./Report";
import Header from "./components/Header";
import Parameters from "./components/Parameters";
import LoadingParameter from "./components/LoadingParameter";

const Dashboard = () => {
  const {
    usersOptions,
    filterOption,
    handleFilterChange,
    ExportReport,
    isCollapsed,
    collapseFilters,
    loading,
    statisticsParameters,
    chartStates,
    exportingReport,
  } = useActions();
  const { view, flow, report } = filterOption;

  return (
    <Section
      id="dashboard"
      className="!p-4 !m-0 min-h-screen rounded-lg bg-gray-50 flex justify-center"
      innerClassName="lg:max-w-[85%] pb-[2rem]"
    >
      <Filters
        usersOptions={usersOptions}
        handleFilterChange={handleFilterChange}
        filterOption={filterOption}
        isCollapsed={isCollapsed}
        collapseFilters={collapseFilters}
      />
      <Header
        exportingReport={exportingReport}
        reportType={report}
        ExportReport={ExportReport}
        collapseFilters={collapseFilters}
      />

      <div className="flex flex-col gap-4 md:gap-8 mt-4 md:mt-8">
        {loading ? (
          <LoadingParameter />
        ) : (
          <Parameters flow={flow} view={view} stats={statisticsParameters} />
        )}
        <Report type={report} {...filterOption} charts={chartStates} />
      </div>
      {/* <Stats /> */}
      {/* <BarChartInteractive /> */}
    </Section>
  );
};

export default Dashboard;
