import BaseService from "../../../../services/BaseService";
import { API_ENDPOINTS } from "../../../../utils/api-endpoints";
import {
  AllDTFilter,
  RequestMethod,
  PeopleForm,
} from "../../../../utils/types";

export const GetPeople = async (requestBody: AllDTFilter) =>
  await BaseService(API_ENDPOINTS.GET_PEOPLE, RequestMethod.POST, requestBody);
export const GetPeopleList = async (requestBody: {
  searchTerm: string;
  skip: number;
  take: number;
}) =>
  await BaseService(
    API_ENDPOINTS.GET_PEOPLE_LIST,
    RequestMethod.POST,
    requestBody
  );
export const GetPeopleById = async (id: string) =>
  await BaseService(`${API_ENDPOINTS.GET_TEAM_BY_ID}/${id}`, RequestMethod.GET);

export const DeletePeople = async (id: string) =>
  await BaseService(
    `${API_ENDPOINTS.DELETE_PEOPLE}/${id}`,
    RequestMethod.DELETE
  );
export const EditPeople = async (requestBody: PeopleForm) =>
  await BaseService(
    API_ENDPOINTS.UPDATE_PEOPLE,
    RequestMethod.POST,
    requestBody
  );
