import {
  ICardContent,
  FlowEnum,
  GraphTypeEnum,
  ReportTypeEnum,
  ViewEnum,
} from "../../../../utils/types";

type ParameterCardContent = {
  [key in string]?: {
    [key in ViewEnum]?: {
      [key in FlowEnum]?: { tooltip: string };
    };
  };
};
const parameterContent: ParameterCardContent = {
  Messages: {
    [ViewEnum.ALL]: {
      [FlowEnum.ALL]: {
        tooltip: "Sent & received messages",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "All received messages",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "All sent messages",
      },
    },
    [ViewEnum.INTERNAL]: {
      [FlowEnum.ALL]: {
        tooltip: "All internal messages",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received messages internally",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent messages internally",
      },
    },
    [ViewEnum.EXTERNAL]: {
      [FlowEnum.ALL]: {
        tooltip: "All External messages",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received messages externally",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent messages externally",
      },
    },
  },
  "Response Time": {
    [ViewEnum.ALL]: {
      [FlowEnum.ALL]: {
        tooltip: "Sent & received average response time",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received average response time",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent average response time",
      },
    },
    [ViewEnum.INTERNAL]: {
      [FlowEnum.ALL]: {
        tooltip: "Internal response time",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Average time response of received message internally",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Average time response of sent message internally",
      },
    },
    [ViewEnum.EXTERNAL]: {
      [FlowEnum.ALL]: {
        tooltip: "External response time",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Average time response of received message externally",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Average time response of sent message externally",
      },
    },
  },
  Participants: {
    [ViewEnum.ALL]: {
      [FlowEnum.ALL]: {
        tooltip: "All domains",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received messages domains",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent messages domains",
      },
    },
    [ViewEnum.INTERNAL]: {
      [FlowEnum.ALL]: {
        tooltip: "Internal domains",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received internal domains",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent internal domains",
      },
    },
    [ViewEnum.EXTERNAL]: {
      [FlowEnum.ALL]: {
        tooltip: "External domains",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received external domains",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent external domains",
      },
    },
  },
  Involvement: {
    [ViewEnum.ALL]: {
      [FlowEnum.ALL]: {
        tooltip: "All CC domains",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received domain CC",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent domain CC",
      },
    },
    [ViewEnum.INTERNAL]: {
      [FlowEnum.ALL]: {
        tooltip: "Internal CC domains",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received internal CC",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent internal CC",
      },
    },
    [ViewEnum.EXTERNAL]: {
      [FlowEnum.ALL]: {
        tooltip: "External CC domains",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received external CC",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent external CC",
      },
    },
  },
  Subjects: {
    [ViewEnum.ALL]: {
      [FlowEnum.ALL]: {
        tooltip: "All subjects",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received subjects",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent subjects",
      },
    },
    [ViewEnum.INTERNAL]: {
      [FlowEnum.ALL]: {
        tooltip: "Internal subjects",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received internal subjects",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent internal subjects",
      },
    },
    [ViewEnum.EXTERNAL]: {
      [FlowEnum.ALL]: {
        tooltip: "External subjects",
      },
      [FlowEnum.INFLOW]: {
        tooltip: "Received external subjects",
      },
      [FlowEnum.OUTFLOW]: {
        tooltip: "Sent external subjects",
      },
    },
  },
};
type ReportCardContent = {
  [key in ReportTypeEnum]?: {
    [graphType in GraphTypeEnum]?: {
      [view in ViewEnum]?: {
        [flow in FlowEnum]?: ICardContent;
      };
    };
  };
};
const cardContent: ReportCardContent = {
  [ReportTypeEnum.STATISTICS]: {
    [GraphTypeEnum.VOLUME]: {
      [ViewEnum.ALL]: {
        [FlowEnum.ALL]: {
          title: "Volume of all messages",
          description: "Analyze all messages by period selected",
          tooltip: "Calculated by all messages to selected period",
        },
        [FlowEnum.INFLOW]: {
          title: "Volume of All received messages",
          description: "Analyze All received messages by period selected",
          tooltip: "Calculated by All received messages to selected period",
        },
        [FlowEnum.OUTFLOW]: {
          title: "Volume of all sent messages",
          description: "Analyze all sent messages by period selected",
          tooltip: "Calculated by all sent  messages to selected period",
        },
      },
      [ViewEnum.INTERNAL]: {
        [FlowEnum.ALL]: {
          title: "Volume of all internal messages",
          description: "Analyze all internal messages by period selected",
          tooltip: "Calculated by all internal messages to selected period",
        },
        [FlowEnum.INFLOW]: {
          title: "Volume of received messages internally",
          description:
            "Analyze received messages internally by period selected",
          tooltip:
            "Calculated by received messages internally to selected period",
        },
        [FlowEnum.OUTFLOW]: {
          title: "Volume of Sent messages internally",
          description: "Analyze Sent messages internally  by period selected",
          tooltip: "Calculated bySent messages internally to selected period",
        },
      },
      [ViewEnum.EXTERNAL]: {
        [FlowEnum.ALL]: {
          title: "Volume of all External messages",
          description: "Analyze all External messages by period selected",
          tooltip: "Calculated by all External messages to selected period",
        },
        [FlowEnum.INFLOW]: {
          title: "Volume of received messages externally messages",
          description:
            "Analyze received messages externally by period selected",
          tooltip:
            "Calculated by received messages externally to selected period",
        },
        [FlowEnum.OUTFLOW]: {
          title: "Volume of Sent messages externally",
          description: "Analyze Sent messages externally by period selected",
          tooltip: "Calculated by Sent messages externally to selected period",
        },
      },
    },
    [GraphTypeEnum.RESPOND_TIME_DISTRIBUTION]: {
      [ViewEnum.ALL]: {
        [FlowEnum.ALL]: {
          title: "Respond time distribution",
          description: "Explore reaction of user to all messages",
          tooltip: "Calculated by average response time to all messages",
        },
        [FlowEnum.INFLOW]: {
          title: "Respond time distribution",
          description: "Explore reaction of user to all received messages",
          tooltip:
            "Calculated by average response time to all received messages",
        },
        [FlowEnum.OUTFLOW]: {
          title: "Respond time distribution",
          description: "Explore reaction of user to all sent messages",
          tooltip: "Calculated by average response time to all sent messages",
        },
      },
      [ViewEnum.INTERNAL]: {
        [FlowEnum.ALL]: {
          title: "Respond time distribution",
          description: "Explore reaction of user to all internal messages",
          tooltip:
            "Calculated by average response time to all internal messages",
        },
        [FlowEnum.INFLOW]: {
          title: "Respond time distribution",
          description:
            "Explore reaction of user to all received messages internally",
          tooltip:
            "Calculated by average response time to all received messages internally",
        },
        [FlowEnum.OUTFLOW]: {
          title: "Respond time distribution",
          description:
            "Explore reaction of user to all sent messages internally",
          tooltip:
            "Calculated by average response time to all sent messages internally",
        },
      },
      [ViewEnum.EXTERNAL]: {
        [FlowEnum.ALL]: {
          title: "Respond time distribution",
          description: "Explore reaction of user to all external messages",
          tooltip:
            "Calculated by average response time to all external messages",
        },
        [FlowEnum.INFLOW]: {
          title: "Respond time distribution",
          description:
            "Explore reaction of user to all received messages externally",
          tooltip:
            "Calculated by average response time to all received messages externally",
        },
        [FlowEnum.OUTFLOW]: {
          title: "Respond time distribution",
          description:
            "Explore reaction of user to all sent messages externally",
          tooltip:
            "Calculated by average response time to all sent messages externally",
        },
      },
    },
    [GraphTypeEnum.INVOLVEMENT_RECIPIENTS]: {
      [ViewEnum.ALL]: {
        [FlowEnum.ALL]: {
          title: "Involvement recipients",
          description:
            "Understand the scope of which user is involve with and got involved by others",
          tooltip: "Calculated by sent CC and received CC",
        },
        [FlowEnum.INFLOW]: {
          title: "Involvement recipients",
          description:
            "Understand the scope of which user is involve with received domain CC",
          tooltip: "Calculated by sent received domain CC",
        },
        [FlowEnum.OUTFLOW]: {
          title: "Involvement recipients",
          description:
            "Understand the scope of which user is involve with sent domain CC",
          tooltip: "Calculated by sent domain CC",
        },
      },
      [ViewEnum.INTERNAL]: {
        [FlowEnum.ALL]: {
          title: "Involvement recipients",
          description:
            "Understand the scope of which user is involve with internal CC domains",
          tooltip: "Calculated by internal CC domains",
        },
        [FlowEnum.INFLOW]: {
          title: "Involvement recipients",
          description:
            "Understand the scope of which user is involve with received internal CC",
          tooltip: "Calculated by sent received internal CC",
        },
        [FlowEnum.OUTFLOW]: {
          title: "Involvement recipients",
          description:
            "Understand the scope of which user is involve with sent internal CC",
          tooltip: "Calculated by sent internal CC",
        },
      },
      [ViewEnum.EXTERNAL]: {
        [FlowEnum.ALL]: {
          title: "Involvement recipients",
          description:
            "Understand the scope of which user is involve with external CC domains",
          tooltip: "Calculated by external CC domains",
        },
        [FlowEnum.INFLOW]: {
          title: "Involvement recipients",
          description:
            "Understand the scope of which user is involve with received external CC",
          tooltip: "Calculated by sent received external CC",
        },
        [FlowEnum.OUTFLOW]: {
          title: "Involvement recipients",
          description:
            "Understand the scope of which user is involve with sent external CC",
          tooltip: "Calculated by sent external CC",
        },
      },
    },
    [GraphTypeEnum.CONNECTIVITY_SCOPE]: {
      [ViewEnum.ALL]: {
        [FlowEnum.ALL]: {
          title: "Connectivity scope",
          description:
            "Find out connectivity scope between user to all domains",
          tooltip: "Calculated by all domain types",
        },
        [FlowEnum.INFLOW]: {
          title: "Connectivity scope",
          description:
            "Find out connectivity scope between user to received messages domains",
          tooltip: "Calculated by all received messages domains",
        },
        [FlowEnum.OUTFLOW]: {
          title: "Connectivity scope",
          description:
            "Find out connectivity scope between user to sent messages domains",
          tooltip: "Calculated by all sent messages domains",
        },
      },
      [ViewEnum.INTERNAL]: {
        [FlowEnum.ALL]: {
          title: "Connectivity scope",
          description:
            "Find out connectivity scope between user to Internal domains",
          tooltip: "Calculated by all Internal domains",
        },
        [FlowEnum.INFLOW]: {
          title: "Connectivity scope",
          description:
            "Find out connectivity scope between user to received messages domains",
          tooltip: "Calculated by all received messages domains",
        },
        [FlowEnum.OUTFLOW]: {
          title: "Connectivity scope",
          description:
            "Find out connectivity scope between user to sent internal domains",
          tooltip: "Calculated by all sent internal domains",
        },
      },
      [ViewEnum.EXTERNAL]: {
        [FlowEnum.ALL]: {
          title: "Connectivity scope",
          description:
            "Find out connectivity scope between user to external domains",
          tooltip: "Calculated by all external domains",
        },
        [FlowEnum.INFLOW]: {
          title: "Connectivity scope",
          description:
            "Find out connectivity scope between user to received external domains",
          tooltip: "Calculated by all received external domains",
        },
        [FlowEnum.OUTFLOW]: {
          title: "Connectivity scope",
          description:
            "Find out connectivity scope between user to sent external domains",
          tooltip: "Calculated by all sent external domains",
        },
      },
    },
  },
  [ReportTypeEnum.ANALYTICS]: {
    [GraphTypeEnum.VOLUME]: {
      [ViewEnum.ALL]: {
        [FlowEnum.ALL]: {
          title: "Volume of all messages",
          description: "Analyze all messages by period selected",
          tooltip: "Calculated by all messages to selected period",
        },
        [FlowEnum.INFLOW]: {
          title: "Volume of All received messages",
          description: "Analyze All received messages by period selected",
          tooltip: "Calculated by All received messages to selected period",
        },
        [FlowEnum.OUTFLOW]: {
          title: "Volume of all sent messages",
          description: "Analyze all sent messages by period selected",
          tooltip: "Calculated by all sent messages to selected period",
        },
      },
      [ViewEnum.INTERNAL]: {
        [FlowEnum.ALL]: {
          title: "Volume of all internal messages",
          description: "Analyze all internal messages by period selected",
          tooltip: "Calculated by all internal messages to selected period",
        },
        [FlowEnum.INFLOW]: {
          title: "Volume of received messages internally",
          description:
            "Analyze received messages internally by period selected",
          tooltip:
            "Calculated by received messages internally to selected period",
        },
        [FlowEnum.OUTFLOW]: {
          title: "Volume of Sent messages internally",
          description: "Analyze Sent messages internally by period selected",
          tooltip: "Calculated by sent messages internally to selected period",
        },
      },
      [ViewEnum.EXTERNAL]: {
        [FlowEnum.ALL]: {
          title: "Volume of all External messages",
          description: "Analyze all External messages by period selected",
          tooltip: "Calculated by all external messages to selected period",
        },
        [FlowEnum.INFLOW]: {
          title: "Volume of received messages externally",
          description:
            "Analyze received messages externally by period selected",
          tooltip:
            "Calculated by received messages externally to selected period",
        },
        [FlowEnum.OUTFLOW]: {
          title: "Volume of Sent messages externally",
          description: "Analyze Sent messages externally by period selected",
          tooltip: "Calculated by Sent messages externally to selected period",
        },
      },
    },
    [GraphTypeEnum.RESPOND_TIME_DISTRIBUTION]: {
      [ViewEnum.ALL]: {
        [FlowEnum.ALL]: {
          title: "Respond time distribution",
          description: "Explore reaction of user to all messages",
          tooltip: "Calculated by average response time to all messages",
        },
        [FlowEnum.INFLOW]: {
          title: "Respond time distribution",
          description: "Explore reaction of user to all received messages",
          tooltip:
            "Calculated by average response time to all received messages",
        },
        [FlowEnum.OUTFLOW]: {
          title: "Respond time distribution",
          description: "Explore reaction of user to all sent messages",
          tooltip: "Calculated by average response time to all sent messages",
        },
      },
      [ViewEnum.INTERNAL]: {
        [FlowEnum.ALL]: {
          title: "Respond time distribution",
          description: "Explore reaction of user to all internal messages",
          tooltip:
            "Calculated by average response time to all internal messages",
        },
        [FlowEnum.INFLOW]: {
          title: "Respond time distribution",
          description:
            "Explore reaction of user to all received messages internally",
          tooltip:
            "Calculated by average response time to all received messages internally",
        },
        [FlowEnum.OUTFLOW]: {
          title: "Respond time distribution",
          description:
            "Explore reaction of user to all sent messages internally",
          tooltip:
            "Calculated by average response time to all sent messages internally",
        },
      },
      [ViewEnum.EXTERNAL]: {
        [FlowEnum.ALL]: {
          title: "Respond time distribution",
          description: "Explore reaction of user to all external messages",
          tooltip:
            "Calculated by average response time to all external messages",
        },
        [FlowEnum.INFLOW]: {
          title: "Respond time distribution",
          description:
            "Explore reaction of user to all received messages externally",
          tooltip:
            "Calculated by average response time to all received messages externally",
        },
        [FlowEnum.OUTFLOW]: {
          title: "Respond time distribution",
          description:
            "Explore reaction of user to all sent messages externally",
          tooltip:
            "Calculated by average response time to all sent messages externally",
        },
      },
    },
    [GraphTypeEnum.RADAR]: {
      [ViewEnum.ALL]: {
        [FlowEnum.ALL]: {
          title: "",
          description: "",
          tooltip: "",
        },
        [FlowEnum.INFLOW]: {
          title: "",
          description: "",
          tooltip: "",
        },
        [FlowEnum.OUTFLOW]: {
          title: "",
          description: "",
          tooltip: "",
        },
      },
      [ViewEnum.INTERNAL]: {
        [FlowEnum.ALL]: {
          title: "",
          description: "",
          tooltip: "",
        },
        [FlowEnum.INFLOW]: {
          title: "",
          description: "",
          tooltip: "",
        },
        [FlowEnum.OUTFLOW]: {
          title: "",
          description: "",
          tooltip: "",
        },
      },
      [ViewEnum.EXTERNAL]: {
        [FlowEnum.ALL]: {
          title: "",
          description: "",
          tooltip: "",
        },
        [FlowEnum.INFLOW]: {
          title: "",
          description: "",
          tooltip: "",
        },
        [FlowEnum.OUTFLOW]: {
          title: "",
          description: "",
          tooltip: "",
        },
      },
    },
    [GraphTypeEnum.SCOPE]: {
      [ViewEnum.ALL]: {
        [FlowEnum.ALL]: {
          title: "",
          description: "",
          tooltip: "",
        },
        [FlowEnum.INFLOW]: {
          title: "",
          description: "",
          tooltip: "",
        },
        [FlowEnum.OUTFLOW]: {
          title: "",
          description: "",
          tooltip: "",
        },
      },
      [ViewEnum.INTERNAL]: {
        [FlowEnum.ALL]: {
          title: "",
          description: "",
          tooltip: "",
        },
        [FlowEnum.INFLOW]: {
          title: "",
          description: "",
          tooltip: "",
        },
        [FlowEnum.OUTFLOW]: {
          title: "",
          description: "",
          tooltip: "",
        },
      },
      [ViewEnum.EXTERNAL]: {
        [FlowEnum.ALL]: {
          title: "",
          description: "",
          tooltip: "",
        },
        [FlowEnum.INFLOW]: {
          title: "",
          description: "",
          tooltip: "",
        },
        [FlowEnum.OUTFLOW]: {
          title: "",
          description: "",
          tooltip: "",
        },
      },
    },
  },
  [ReportTypeEnum.CONNECTIONS]: {},
};

// Handler function to get card content based on filters
export const getCardContent = (
  report: ReportTypeEnum,
  graphType: GraphTypeEnum,
  view: ViewEnum,
  flow: FlowEnum
) => {
  return (
    cardContent?.[report]?.[graphType]?.[view]?.[flow] || {
      title: "-",
      description: "-",
      tooltip: "-",
    }
  );
};
export const getCardParameter = (
  parameterType: string,
  view: ViewEnum,
  flow: FlowEnum
) => {
  return (
    parameterContent?.[parameterType]?.[view]?.[flow] || {
      tooltip: "-",
    }
  );
};
