import React from "react";
import { Switch, TimePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import Section from "../../../layouts/Section";
import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
import DeleteAccount from "../Account/DeleteAccount";
import { IDay } from "../../../utils/types";
import useSettings from "./hooks/useSettings";
import Loading from "../../../components/ui/Loading";
type RangeValueType<DateType> = [
  start: DateType | null | undefined,
  end: DateType | null | undefined
];
const Settings = () => {
  const {
    daysOfWeek,
    userSettings,
    loading,
    fetching,
    updateSettings,
    handleForm,
  } = useSettings();
  const {
    businessHoursFrom,
    businessHoursTo,
    monthlyReport,
    weeklyReport,
    promotionalNotifications,
    offersNotifications,
    workingDays,
  } = userSettings || {};

  const toggleDay = (index: number) => {
    const updatedDays = workingDays?.includes(index)
      ? workingDays?.filter((day) => day !== index) // Deselect if already selected
      : [...workingDays, index]; // Select if not already selected

    handleForm("workingDays", updatedDays); // Pass updated days to handleForm
  };

  const handleChange = (values: any) => {
    const hours = values?.map((value: any) => value?.format("HH:mm"));
    handleForm("hours", hours);
  };
  const defaultBusinessHours: RangeValueType<Dayjs> = [
    businessHoursFrom ? dayjs(businessHoursFrom, "HH:mm") : null,
    businessHoursTo ? dayjs(businessHoursTo, "HH:mm") : null,
  ];

  return fetching ? (
    <Loading />
  ) : (
    <Section className="max-w-7xl mx-auto !mt-4 !p-4">
      <div className="flex flex-wrap justify-between bg-white z-10 pb-4 mb-4 border-b mt-8">
        <PageTitle
          title={"Settings"}
          subTitle={
            "Customize certain aspects about how your email statistics are calculated"
          }
        />
        <Button
          text={"Save changes"}
          type={"submit"}
          loading={loading}
          onClick={updateSettings}
          className="justify-center items-center !px-4 text-white bg-primaryBlue !max-md:px-5"
        />
      </div>
      <div className="space-y-4">
        <div className="border-b border-gray-900/10 pb-6">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            {/* <div className="sm:col-span-full  justify-between grid  sm:grid-cols-6 grid-flow-row">
                <div className="sm:col-span-4">
                  <span className="block text-sm font-bold leading-6">
                    Take my working hours into account
                  </span>
                  <span className="block text-sm font-medium leading-6 text-placeholder">
                    Update your working days for graphic display
                  </span>
                </div>
                <div className="sm:col-span-2 place-self-end">
                  <Switch checked={workingDaysSwitch} onChange={switchDays} />
                </div>
              </div> */}
            <div className="sm:col-span-full  justify-between grid  sm:grid-cols-6 grid-flow-row">
              <div className="sm:col-span-4">
                <span className="block text-sm font-bold leading-6">
                  Working Days
                </span>
                <span className="block text-sm font-medium leading-6 text-placeholder">
                  Update your working days for graphic display
                </span>
              </div>
              <div className="sm:col-span-2 place-self-end">
                <div className="flex gap-2">
                  {daysOfWeek.map((day: IDay, index: number) => (
                    <button
                      // disabled={!workingDaysSwitch}
                      key={day.value}
                      type="button"
                      onClick={() => toggleDay(index)}
                      className={`size-8 rounded-xl flex items-center justify-center 
              ${
                workingDays?.includes(index)
                  ? "bg-primaryBlue text-white"
                  : "bg-gray-100 text-gray-400"
              }`}
                    >
                      {day.label}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className="sm:col-span-full  justify-between grid  sm:grid-cols-6 grid-flow-row">
              <div className="sm:col-span-2">
                <span className="block text-sm font-bold leading-6">
                  Business hours
                </span>
                <span className="block text-sm font-medium leading-6 text-placeholder">
                  Select business hours for response time metrics
                </span>
              </div>
              <div className="sm:col-span-4 place-self-end">
                <TimePicker.RangePicker
                  value={defaultBusinessHours}
                  minuteStep={15}
                  //   use12Hours
                  format="HH:mm"
                  onChange={handleChange}
                  className="min-h-[2.5rem]"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-gray-900/10 pb-6">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-full  justify-between grid  sm:grid-cols-6 grid-flow-row">
              <div className="sm:col-span-4">
                <span className="block text-sm font-bold leading-6">
                  Monthly report
                </span>
                <span className="block text-sm font-medium leading-6 text-placeholder">
                  Receive an email at the beginning of the month with your last
                  month’s statistics
                </span>
              </div>
              <div className="sm:col-span-2 place-self-end">
                <Switch
                  checked={monthlyReport}
                  onChange={(value: boolean) =>
                    handleForm("monthlyReport", value)
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-gray-900/10 pb-6">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-full  justify-between grid  sm:grid-cols-6 grid-flow-row">
              <div className="sm:col-span-4">
                <span className="block text-sm font-bold leading-6">
                  Weekly report
                </span>
                <span className="block text-sm font-medium leading-6 text-placeholder">
                  Receive an email at the beginning of the week with your last
                  week’s statistics
                </span>
              </div>
              <div className="sm:col-span-2 place-self-end">
                <Switch
                  checked={weeklyReport}
                  onChange={(value: boolean) =>
                    handleForm("weeklyReport", value)
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-gray-900/10 pb-12">
          <div className="mb-4">
            <span className="block text-sm font-bold leading-6">
              Notifications
            </span>
            <span className="block text-sm font-medium leading-6 text-placeholder">
              We'll always let you know about important changes, but you pick
              what else you want to hear about.
            </span>
          </div>
          <fieldset>
            <legend className="text-sm font-semibold leading-6 text-gray-900">
              By Email
            </legend>
            <div className="mt-2 space-y-4">
              <div className="relative flex gap-x-3">
                <div className="flex h-6 items-center">
                  <input
                    id="offersNotifications"
                    name="offersNotifications"
                    type="checkbox"
                    checked={offersNotifications}
                    onChange={(e) =>
                      handleForm("offersNotifications", e.target.checked)
                    }
                    className="h-4 w-4 rounded border-gray-300 text-primaryBlue focus:ring-primaryBlue"
                  />
                </div>
                <div className="text-sm leading-6">
                  <label
                    htmlFor="offersNotifications"
                    className="font-medium text-gray-900"
                  >
                    Offers
                  </label>
                  <p className="text-gray-400">
                    Get notified when a candidate accepts or rejects an offer.
                  </p>
                </div>
              </div>
              <div className="relative flex gap-x-3">
                <div className="flex h-6 items-center">
                  <input
                    id="promotionalNotifications"
                    name="promotionalNotifications"
                    type="checkbox"
                    checked={promotionalNotifications}
                    onChange={(e) =>
                      handleForm("promotionalNotifications", e.target.checked)
                    }
                    className="h-4 w-4 rounded border-gray-300 text-primaryBlue focus:ring-primaryBlue"
                  />
                </div>
                <div className="text-sm leading-6">
                  <label
                    htmlFor="promotionalNotifications"
                    className="font-medium text-gray-900"
                  >
                    Promotional
                  </label>
                  <p className="text-gray-400">
                    Get notified when someones posts a comment on a posting.
                  </p>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mt-4">
        <div className="sm:col-span-full justify-between grid sm:grid-cols-6 grid-flow-row">
          <div className="sm:col-span-4">
            <span className="block text-sm font-bold leading-6">
              Delete account
            </span>
            <span className="block text-sm font-medium leading-6 text-placeholder">
              Erase your account and all data associated with it
            </span>
          </div>
          <DeleteAccount />
        </div>
      </div>
    </Section>
  );
};

export default Settings;
