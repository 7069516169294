import React, { useEffect, useState } from "react";
import { GetDashboardPricing } from "../../../../modules/common/service";
import { DashboardPricing, SubscriptionType } from "../../../../utils/types";
import Loading from "../../../../components/ui/Loading";
import Button from "../../../../components/Button";
import { useNavigate } from "react-router-dom";

const PricingTable = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  // const [isAnnual, setIsAnnual] = useState(false);
  const [data, setData] = useState<DashboardPricing | undefined>(undefined);
  const getPricing = async () => {
    setLoading(true);
    await GetDashboardPricing()
      .then((response) => {
        setData(response?.data);
      })
      .catch((e) => console.log("GetDashboardPricing", e?.data?.message))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    let isMounted = false;
    if (!isMounted) {
      getPricing();
    }
    return () => {
      isMounted = true;
    };
  }, []);
  return loading ? (
    <Loading />
  ) : (
    <div className="w-full px-4 md:px-6 py-8 relative">
      {data?.currentPlan ? (
        <div className="mb-4 pb-4 border-b border-slate-200">
          <span className="border border-neutral-200 rounded-full py-1 px-4 text-sm">
            Your Current Plan: <strong>{data?.currentPlan?.title}</strong>
          </span>
          <p className="text-placeholder text-sm mt-2 px-4">
            You are on the Free plan. Upgrade to unlock advanced
            features
          </p>
        </div>
      ) : null}
      {/* <div className="flex gap-2 items-center whitespace-nowrap">
        <span className={classNames(!isAnnual ? "text-black font-bold" : "")}>
          Monthly
        </span>
        <div className="relative">
          <input
            type="checkbox"
            id="toggle"
            className="peer sr-only"
            checked={isAnnual}
            onChange={() => setIsAnnual(!isAnnual)}
          />
          <label
            htmlFor="toggle"
            className="relative flex h-6 w-11 cursor-pointer items-center rounded-full bg-slate-400 px-0.5 outline-slate-400 transition-colors before:h-5 before:w-5 before:rounded-full before:bg-white before:shadow-sm before:transition-transform before:duration-150 peer-checked:bg-primaryBlue peer-checked:before:translate-x-full peer-focus-visible:outline peer-focus-visible:outline-offset-2 peer-focus-visible:outline-gray-400 peer-checked:peer-focus-visible:outline-indigo-500"
          >
            <span className="sr-only">Pay Yearly</span>
          </label>
        </div>
        <span className={classNames(isAnnual ? "text-black font-bold" : "")}>
          Yearly
        </span>
        <div className="bg-green-400/30 rounded-lg px-2 py-1">
          <span className="text-green-600 text-xs">Save 20%</span>
        </div>
      </div> */}

      <div className=" grid md:grid-cols-3 gap-4 mt-4 text-sm">
        {data?.plans?.map((plan, index) => (
          <div
            key={index}
            className="relative bg-white border rounded-xl p-4 pb-0 flex flex-col max-md:col-span-3"
          >
            <div className="mb-5">
              <div className="font-semibold text-lg text-slate-900 mb-4">
                {plan.title}
              </div>
              <div className="mb-1">
                <span className="text-xl font-medium text-slate-900">₪</span>
                <span className="text-3xl font-bold text-slate-900">
                  {/* {isAnnual ? plan.priceYearly : plan.pricingMonthly} */}
                  {plan.pricingMonthly}
                </span>
                <span className="text-slate-500 font-medium">/month</span>
              </div>
            </div>
            <div className="pb-4">
              <Button
                onClick={() =>
                  navigate("/checkout", {
                    state: {
                      plan,
                      pricingId: plan.id,
                      paymentType: SubscriptionType.MONTHLY,
                      // paymentType: isAnnual
                      //   ? SubscriptionType.ANNUAL
                      //   : SubscriptionType.MONTHLY,
                    },
                  })
                }
                variant={plan?.isOwn ? "disabled" : "primary"}
                text={plan?.isOwn ? "Your current plan" : "Upgrade"}
                className="w-full"
              />
            </div>
            {plan.features.map((feature, featureIndex) => (
              <div
                key={featureIndex}
                className="flex items-center border-t border-slate-200 py-2 text-slate-600"
              >
                {plan.features.includes(feature) ? (
                  <span>&#10003;</span>
                ) : (
                  <span>&#10007;</span>
                )}
                <span className="ml-2">{feature}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingTable;
