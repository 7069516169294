import { FaChartColumn } from "react-icons/fa6";
import { BsClipboardData } from "react-icons/bs";
import { LuShieldCheck } from "react-icons/lu";
import { IoDocumentTextOutline } from "react-icons/io5";
import { GoRocket } from "react-icons/go";
import { RiFireLine, RiTeamLine } from "react-icons/ri";
import { FiDatabase } from "react-icons/fi";

export const Chunk1 = [
  {
    Icon: GoRocket,
    title: "Boost Productivity",
    description:
      "Clear insights into email habits lead to smarter time management and reduced inefficiencies.",
  },
  {
    Icon: RiTeamLine,
    title: "Team Collaboration",
    description:
      "Understanding team communication patterns and balancing workloads improve collective productivity.",
  },
  {
    Icon: BsClipboardData,
    title: "Data-Driven Decisions",
    description:
      "Custom reports and actionable insights help clients make informed decisions to optimize processes and strategies.",
  },
  {
    Icon: FiDatabase,
    title: "Data Aggregation",
    description:
      "Aggregating and consolidating email data into a unified view is essential for comprehensive decision-making, saving time, and ensuring accuracy.",
  },
];
export const Chunk2 = [
  {
    Icon: IoDocumentTextOutline,
    title: "Improve Responsiveness",
    description:
      "Tracking response times ensures prompt handling of important emails, improving communication speed.",
  },
  {
    Icon: FaChartColumn,
    title: "Simplify Reporting",
    description:
      "Real-time automated reports allow you to understand how work is being done without guesswork, inquiries, or manual tracking.",
  },
  {
    Icon: RiFireLine,
    title: "Identify Trends",
    description:
      "Long-term monitoring of trends helps optimize business processes as email patterns evolve.",
  },
  {
    Icon: LuShieldCheck,
    title: "Privacy Protection",
    description:
      "Email activity is analyzed without accessing content, ensuring compliance with privacy standards.",
  },
];
