const Status = ({ status, small }: { status: boolean; small?: boolean }) => {
  const defaultClass = `font-medium px-4 py-1 rounded-${
    small ? "md !px-2" : "lg"
  } border`;
  let statusColor = "";
  let statusName = "";
  switch (status) {
    case true:
      statusColor = "bg-green-100 text-green-800 border-green-300";
      statusName = "Paid";
      break;
    case false:
      statusColor = "bg-blue-100 text-blue-800 border-blue-300";
      statusName = "Pending";
      break;

    default:
      break;
  }
  return (
    <span className={`${defaultClass} ${statusColor} ${small && "text-xs"}`}>
      {statusName}
    </span>
  );
};

export default Status;
