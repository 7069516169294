import React, { useEffect, useState } from "react";
import Section from "../../layouts/Section";
import { motion, AnimatePresence } from "framer-motion";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { TiStarFullOutline } from "react-icons/ti";

const testimonials = [
  { id: 1, role: "CFO", text: "This product is amazing!" },
  {
    id: 2,
    role: "Project Manager",
    text: "I had a great experience. I had a great experience. I had a great experience.",
  },
  { id: 3, role: "CFO", text: "Highly recommend to everyone!" },
  {
    id: 4,
    role: "Marketing Agency",
    text: "Excellent service and quality.Excellent service and quality. Excellent service and quality. Excellent service and quality.",
  },
  {
    id: 5,
    role: "CTO",
    text: "Will buy again for sure. I had a great experience. I had a great experience. I had a great experience.",
  },
  { id: 6, role: "", text: "Will buy again for sure." },
  { id: 11, text: "This product is amazing!" },
  {
    id: 7,
    role: "Manger",
    text: "I had a great experience. I had a great experience. I had a great experience. I had a great experience. I had a great experience.",
  },
  { id: 8, role: "Designer", text: "Highly recommend to everyone!" },
  {
    id: 9,
    role: "CTO",
    text: "Excellent service and  Excellent service and  Excellent service and  quality.",
  },
  { id: 10, role: "CTO", text: "Will buy again for sure." },
];
const AUTO_LOOP_INTERVAL = 3000;
const TestimonialsCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState<"left" | "right" | null>(null);

  const slideVariants = {
    hiddenRight: { x: "100%", opacity: 0 },
    hiddenLeft: { x: "-100%", opacity: 0 },
    visible: {
      x: "0%",
      opacity: 1,
      transition: { duration: 0.6 },
    },
    exit: {
      opacity: 0,
      scale: 0.9,
      transition: { duration: 0.5 },
    },
  };

  const handleNext = () => {
    setDirection("right");
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrevious = () => {
    setDirection("left");
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  const handleDotClick = (index: number) => {
    setDirection(index > currentIndex ? "right" : "left");
    setCurrentIndex(index);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, AUTO_LOOP_INTERVAL);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [currentIndex]);

  return (
    <Section badge="Testimonials" title="What our users say" className="!pb-0">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 mt-8">
        <div className="relative flex items-center justify-center">
          <AnimatePresence custom={direction}>
            <motion.div
              key={currentIndex}
              initial={direction === "right" ? "hiddenRight" : "hiddenLeft"}
              animate="visible"
              exit="exit"
              variants={slideVariants}
              className="rounded-lg text-center bg-gray-50 p-6  w-[90%] max-w-md sm:p-8"
            >
              <div className="flex flex-col items-center gap-4">
                <img
                  loading="lazy"
                  src={`https://i.pravatar.cc/150?img=${currentIndex + 1}`}
                  alt={`User ${currentIndex + 1}`}
                  className="h-16 w-16 rounded-full object-cover"
                />
                <span className="text-xs text-gray-500">
                  {testimonials[currentIndex].role || "Anonymous"}
                </span>
                <div className="flex items-center">
                  {[...Array(5)].map((item, i) => (
                    <TiStarFullOutline key={i} size={15} color={"#E8B903"} />
                  ))}
                </div>
                <p className="mt-2 text-gray-700">
                  {testimonials[currentIndex].text}
                </p>
              </div>
            </motion.div>
          </AnimatePresence>

          {/* Navigation Controls */}
          <motion.div
            className="absolute left-4 p-2 bg-gray-100 rounded-full  cursor-pointer hover:bg-gray-200"
            whileHover={{ scale: 1.1 }}
            onClick={handlePrevious}
          >
            <FaChevronLeft size={20} />
          </motion.div>
          <motion.div
            className="absolute right-4 p-2 bg-gray-100 rounded-full  cursor-pointer hover:bg-gray-200"
            whileHover={{ scale: 1.1 }}
            onClick={handleNext}
          >
            <FaChevronRight size={20} />
          </motion.div>
        </div>

        <div className="mt-6 flex items-center justify-center gap-2">
          {testimonials.map((_, index) => (
            <motion.div
              key={index}
              className={`size-2 rounded-full cursor-pointer ${
                currentIndex === index
                  ? "bg-primaryBlue"
                  : "bg-linenSecondary hover:bg-gray-400"
              }`}
              onClick={() => handleDotClick(index)}
              whileHover={{ scale: 1.2 }}
              animate={currentIndex === index ? { scale: 1.4 } : {}}
            />
          ))}
        </div>
      </div>
    </Section>
  );
};

export default TestimonialsCarousel;
