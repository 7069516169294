import {
  CardContentFilter,
  IChartDetails,
  ITopics,
} from "../../../../../utils/types";
import CardWrapper from "../../../../../components/CardWrapper";
import { ScrollArea } from "../../../../../components/ui/scroll-area";
import TopicsList from "./components/TopicsList";

const Scope = ({
  filters,
  className,
  details,
}: {
  filters: CardContentFilter;
  className: string;
  details: IChartDetails<ITopics>;
}) => {
  const { data, error, loading, needSubscription } = details;
  return (
    <CardWrapper
      className={className}
      title={"Topics menu"}
      description={"Raise awareness about main subjects users working on"}
      tooltip={"Subjects presented by period selected"}
      error={error}
      loading={loading}
      needSubscription={needSubscription}
      content={
        <ScrollArea className="h-0 min-h-[400px]">
          <TopicsList data={data} />
          {/* <TreemapChart data={topicsData2} filters={filters} /> */}
        </ScrollArea>
      }
    />
  );
};

export default Scope;
