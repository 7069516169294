import React, { useState } from "react";
import Section from "../../layouts/Section";
import SEO from "../../layouts/SEO";
import PageTitle from "../../components/PageTitle";
import Icon from "../../components/ui/Buttons/Icon";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong, FaCheck } from "react-icons/fa6";
import Form from "../../components/Form";
import { Contact, ContactFormValues } from "../../utils/types";
import { useAlertsContext } from "../../hooks/useAlertsContext";
import images from "../../data/images";

const inputs = [
  {
    id: 1,
    label: "First Name*",
    name: "firstName",
    placeholder: "First Name",
    required: true,
    value: "",
  },
  {
    id: 2,
    label: "Last Name*",
    name: "lastName",
    placeholder: "Last Name",
    required: true,
    value: "",
  },
  {
    id: 3,
    label: "Telephone*",
    name: "telephone",
    placeholder: "Telephone",
    type: "tel",
    required: true,
    value: "",
  },
  {
    id: 4,
    label: "Email*",
    name: "email",
    placeholder: "Email",
    type: "email",
    required: true,
    value: "",
  },
  //   {
  //     id: 5,
  //     label: "Country",
  //     name: "country",
  //     placeholder: "Country",
  //     component: CountrySelect,
  //     value: "",
  //   },
  {
    id: 6,
    label: "Company",
    name: "company",
    placeholder: "Company",
    required: true,
    value: "",
  },
  {
    id: 7,
    label: "Role",
    name: "role",
    placeholder: "Role",
    value: "",
  },
  {
    id: 8,
    label:
      "Why do you need email statistics and what would you like to achieve?",
    name: "achieve",
    placeholder: "to achieve...",
    textArea: true,
    value: "",
  },
  {
    id: 9,
    label: "How many accounts are you looking to monitor?",
    name: "accounts",
    placeholder: "10+",
    textArea: true,
    value: "",
  },
];

const initialValues: ContactFormValues = {
  fullName: "",
  company: "",
  email: "",
  telephone: "",
  country: "",
  subject: "",
  message: "",
};
type Props = {
  callBack?: Function;
};
const RequestDemo: React.FC<Props> = ({ callBack }) => {
  const navigate = useNavigate();
  const { toastSuccess } = useAlertsContext();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] =
    React.useState<ContactFormValues>(initialValues);

  const handleSubmitContactUs = async (values: ContactFormValues) => {
    setLoading(true);
    const formValues: Contact = {
      name: values.fullName,
      email: values.email,
      phoneNumber: values.telephone,
      info: values.message,
      subject: values.subject,
      company: values.company,
      country: values.country,
    };
    // Handle submission for contact us form
    //     await ContactUs(formValues)
    //       .then((response) => {
    //         toastSuccess(response || "Form Submitted Successfully");
    //         setFormValues(initialValues);
    //         callBack &&
    //           setTimeout(() => {
    //             callBack();
    //           }, 1000);
    //       })
    //       .catch((e) => toastError(e?.data))
    //       .finally(() => setLoading(false));
    console.log(formValues);
    toastSuccess("Form Submitted Successfully");
    setFormValues(initialValues);
    callBack &&
      setTimeout(() => {
        callBack();
      }, 1000);
    navigate("/Dashboard");
  };
  const handleResetContactUs = () => {
    // Handle reset for contact us form
    setFormValues(initialValues);
  };
  return (
    <Section id="RequestDemo" className="!p-0 !m-0">
      <SEO title={"Request a demo | InterSight"} />
      <div className="px-20 max-md:px-0 py-8 grid grid-cols-3 size-full">
        <div className="p-6 lg:col-span-1 col-span-3">
          <div
            className="flex items-center gap-4 cursor-pointer w-fit"
            onClick={() => navigate(-1)}
          >
            <FaArrowLeftLong />
            <span>{"Go back"}</span>
          </div>
          <PageTitle title={"Request a demo"} />
          <div className="bg-gray-50 w-full p-4 my-4 rounded-lg flex items-center justify-center">
            <img src={images.analyticsInterface3} loading="lazy" alt="InterSight logo" />
          </div>
          <p className="text-gray-800 lg:text-lg">
            {
              "We’re looking forward to get to know you and your team. Together, we will look at how to get the most out of InterSight within your company. We will contact you shortly to book a demo at a convenient time for you."
            }
          </p>
          <ul className="mt-4 font-semibold lg:text-lg">
            <li className="mt-2">
              <div className="flex items-center gap-2">
                <Icon className="bg-primaryBlue bg-opacity-10 !rounded-full text-primaryBlue">
                  <FaCheck />
                </Icon>
                <span>1:1 product walkthrough with an expert</span>
              </div>
            </li>
            <li className="mt-2">
              <div className="flex items-center gap-2">
                <Icon className="bg-primaryBlue bg-opacity-10 !rounded-full text-primaryBlue">
                  <FaCheck />
                </Icon>
                <span>
                  Get an email statistics dashboard tailored to your needs
                </span>
              </div>
            </li>
            <li className="mt-2">
              <div className="flex items-center gap-2">
                <Icon className="bg-primaryBlue bg-opacity-10 !rounded-full text-primaryBlue">
                  <FaCheck />
                </Icon>
                <span>Discover different business use cases</span>
              </div>
            </li>
            <li className="mt-2">
              <div className="flex items-center gap-2">
                <Icon className="bg-primaryBlue bg-opacity-10 !rounded-full text-primaryBlue">
                  <FaCheck />
                </Icon>
                <span>Answers to all of your questions</span>
              </div>
            </li>
          </ul>
        </div>
        <div className="lg:col-span-2 col-span-3 gap-4 p-4 bg-gray-50 rounded-lg">
          <Form
            inputs={inputs}
            formValues={formValues}
            setFormValues={setFormValues}
            onSubmit={handleSubmitContactUs}
            onReset={handleResetContactUs}
            loading={loading}
            submitText={"Create an account"}
          />
        </div>
      </div>
    </Section>
  );
};

export default RequestDemo;
