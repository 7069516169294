import { useEffect, useState } from "react";
import { useAlertsContext } from "../../../hooks/useAlertsContext";
import {
  ChartsTypeEnum,
  FlowEnum,
  OptionsFilterType,
  ReportTypeEnum,
  StatProp,
  ViewEnum,
} from "../../../utils/types";
import type { SelectProps } from "antd";
import {
  DownloadCSVReport,
  GetCommunicationMetrics,
  GetConnectionsAnalysis,
  GetConnectivityRanks,
  GetConnectivityScope,
  GetInvolvementRecipients,
  GetMessagesVolume,
  GetRespondTimeDistribution,
  GetStatistics,
  GetTopicsMenu,
} from "../api/service";
import { IChartData, initData } from "./data";
import { GetPeopleList } from "../People/services/service";
import AuthActions from "../../../modules/auth/actions";
import { useAppDispatch } from "../../../hooks/useStore";

const initialFilterOption = {
  flow: FlowEnum.ALL,
  view: ViewEnum.ALL,
  report: ReportTypeEnum.STATISTICS,
  user2: undefined,
  user1: undefined,
  dateRange: {
    from: null,
    to: null,
  },
  isSearch: false,
};
const useActions = () => {
  const { toastError, toastPromise } = useAlertsContext();
  const { fetchSubscriptionPermission } = AuthActions();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [exportingReport, setExportingReport] = useState(false);
  const [chartStates, setChartStates] =
    useState<Record<ChartsTypeEnum, IChartData>>(initData);
  const [usersOptions, setUsersOptions] = useState<SelectProps["options"]>([]);
  const [statisticsParameters, setStatisticsParameters] = useState<StatProp[]>(
    []
  );
  const [filterOption, setFilterOption] =
    useState<OptionsFilterType>(initialFilterOption);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const collapseFilters = () => {
    setIsCollapsed(!isCollapsed);
  };
  const fetchReportData = async (filters = filterOption) => {
    const chartEndpoints: Record<
      ReportTypeEnum,
      { type: ChartsTypeEnum; call: Function }[]
    > = {
      [ReportTypeEnum.STATISTICS]: [
        { type: ChartsTypeEnum.VOLUME, call: GetMessagesVolume },
        {
          type: ChartsTypeEnum.RESPOND_TIME_DISTRIBUTION,
          call: GetRespondTimeDistribution,
        },
        {
          type: ChartsTypeEnum.CONNECTIVITY_SCOPE,
          call: GetConnectivityScope,
        },
        {
          type: ChartsTypeEnum.INVOLVEMENT_RECIPIENTS,
          call: GetInvolvementRecipients,
        },
      ],
      [ReportTypeEnum.CONNECTIONS]: [
        {
          type: ChartsTypeEnum.CONNECTIVITY_RANKS,
          call: GetConnectivityRanks,
        },
        {
          type: ChartsTypeEnum.CONNECTIVITY_ANALYSIS,
          call: GetConnectionsAnalysis,
        },
      ],
      [ReportTypeEnum.ANALYTICS]: [
        { type: ChartsTypeEnum.TOPICS, call: GetTopicsMenu },
        { type: ChartsTypeEnum.VOLUME, call: GetMessagesVolume },
        {
          type: ChartsTypeEnum.RESPOND_TIME_DISTRIBUTION,
          call: GetRespondTimeDistribution,
        },
        {
          type: ChartsTypeEnum.COMMUNICATION,
          call: GetCommunicationMetrics,
        },
      ],
    };
    const reportTypeEndpoints = chartEndpoints[filters.report];
    reportTypeEndpoints.forEach(({ type }) => {
      setChartStates((prevState) => ({
        ...prevState,
        [type]: {
          ...prevState[type],
          loading: true,
          error: { isOccurred: false, message: "" },
        },
      }));
    });
    reportTypeEndpoints.forEach(async ({ type, call }) => {
      try {
        const result = await call(filters);
        setChartStates((prevState) => ({
          ...prevState,
          [type]: {
            ...prevState[type],
            loading: false,
            data: result.data,
          },
        }));
      } catch (error: any) {
        setChartStates((prevState) => ({
          ...prevState,
          [type]: {
            ...prevState[type],
            loading: false,
            error: {
              isOccurred: true,
              message: error?.message || "Error occurred",
            },
          },
        }));
      }
    });
    // Promise.allSettled(reportTypeEndpoints.map(({ call }) => call(filters)))
    //   .then((results) => {
    //     results.forEach((result, index) => {
    //       const { type } = reportTypeEndpoints[index];
    //       console.log({ result, index });
    //       if (result.status === "fulfilled") {
    //         setChartStates((prevState) => ({
    //           ...prevState,
    //           [type]: {
    //             ...prevState[type],
    //             loading: false,
    //             data: result.value.data,
    //           },
    //         }));
    //       } else {
    //         setChartStates((prevState) => ({
    //           ...prevState,
    //           [type]: {
    //             ...prevState[type],
    //             loading: false,
    //             error: {
    //               isOccurred: true,
    //               message: result.reason?.message || "Error occurred",
    //             },
    //           },
    //         }));
    //       }
    //     });
    //   })
    //   .catch((err) => toastError(err?.data?.message || "Error Occurred"));
  };
  const fetchStatistics = async (filters = filterOption) => {
    setLoading(true);
    await GetStatistics(filters)
      .then((stats) => {
        setStatisticsParameters(stats.data);
        dispatch(fetchSubscriptionPermission());
      })
      .catch((err) => toastError(err?.data?.message || "Error Occurred"))
      .finally(() => setLoading(false));
  };
  const handleFilterChange = (
    filters: OptionsFilterType,
    hasChanges = true,
    isSearch = false
  ) => {
    if (hasChanges) {
      setFilterOption(filters);
      fetchStatistics({ ...filters, isSearch: isSearch });
      fetchReportData(filters);
    }
    setIsCollapsed(!isCollapsed);
  };
  const changeReportType = (report: ReportTypeEnum) => {
    setFilterOption({ ...filterOption, report });
  };
  const ExportReport = () => {
    setExportingReport(true);
    // ajax request after empty completing
    const promise = DownloadCSVReport(filterOption)
      .then((response) => {
        let fileName = "";
        const disposition = response.headers["Content-Disposition"];
        if (disposition && disposition.indexOf("attachment") !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = decodeURIComponent(matches[1].replace(/['"]/g, ""));
          }
        }
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers["content-type"],
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        toastError(e?.data);
      })
      .finally(() => setExportingReport(false));
    toastPromise(
      promise,
      `File exported successfully.`,
      `Error occurred during exporting the file.`
    );
  };

  useEffect(() => {
    fetchReportData();
    fetchStatistics();
    GetUsersList();
  }, []);

  const GetUsersList = async (searchTerm = "") => {
    const searchObj = { searchTerm, skip: 0, take: 50 };
    await GetPeopleList(searchObj)
      .then((response) => {
        const mappedArray = response.data.map(
          (item: { name: string; id: string }) => ({
            label: item.name,
            value: item.id,
          })
        );
        setUsersOptions(mappedArray);
      })
      .catch((error) => console.log(error));
  };
  return {
    statisticsParameters,
    loading,
    changeReportType,
    usersOptions,
    exportingReport,
    ExportReport,
    filterOption,
    setFilterOption,
    handleFilterChange,
    isCollapsed,
    collapseFilters,
    chartStates,
    GetUsersList,
  };
};
export default useActions;
