import { Captions, ChevronRight, Users } from "lucide-react";
import React, { useState } from "react";
import Emails from "./Emails";
import { ITopics } from "../../../../../../utils/types";
import { Empty } from "antd";

const TopicsList = ({ data }: { data: ITopics[] }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const handleTopic = (item: any) => {
    setSelectedItem(item);
    setIsVisible(true);
  };
  const dismiss = () => {
    setSelectedItem(undefined);
    setIsVisible(false);
  };
  return (
    <>
      {data?.length ? (
        <div className="w-[95%] ">
          {data?.map((topic: ITopics, index: number) => (
            <div
              onClick={() => handleTopic(topic)}
              className="flex flex-col border-b border-b-gray-200 gap-x-6 gap-y-2 mb-2 py-2 cursor-pointer group transition-all duration-300 hover:bg-gray-50 hover:rounded-lg hover:border-none hover:px-2"
              key={index}
            >
              <div className="flex justify-between items-end">
                <p className="text-sm font-semibold text-gray-900 w-[20rem] truncate">
                  {topic.name}
                </p>

                <div className="flex items-end gap-2">
                  <div className="flex items-end gap-2">
                    <div className="gap-1 py-1 px-2 min-w-14 items-center justify-center flex bg-lightBlue/20 rounded-full">
                      <Captions size={14} color={"#113FD2"} />
                      <span className="text-xs leading-5 font-bold text-primaryBlue">
                        {topic.count}
                      </span>
                    </div>
                    <div className="gap-1 py-1 px-2 min-w-14 items-center justify-center flex bg-lightBlue/20 rounded-full">
                      <Users size={14} color={"#113FD2"} />
                      <span className="text-xs leading-5 font-bold text-primaryBlue">
                        {topic?.emails?.length}
                      </span>
                    </div>
                  </div>

                  <ChevronRight className="text-gray-400 group-hover:text-gray-950" />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Empty />
      )}
      <Emails dismiss={dismiss} isVisible={isVisible} item={selectedItem} />
    </>
  );
};

export default TopicsList;
