import * as React from "react";
import "../../styles/Navbar.css";
import AnimatedLogo from "./AnimatedLogo";

const BasicBar: React.FC = () => {
  return (
    <div
      // className={`box-border flex flex-col shrink-0 w-full fixed transition-all duration-300 top-0 self-center z-40 items-center justify-center`}
      className={`h-[8dvh] box-border flex flex-col shrink-0 w-full bg-white transition-all duration-300 top-0 self-center z-40 items-center justify-center`}
    >
      <header
        className={`flex relative gap-4 w-full justify-between items-center px-12 bg-white max-md:flex-wrap max-md:px-5`}
      >
        <AnimatedLogo />
      </header>
    </div>
  );
};

export default BasicBar;
