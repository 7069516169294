import * as React from "react";
import images from "../../data/images";
import { MdOutlineCelebration } from "react-icons/md";
import AnimatedShinyText from "../../components/AnimatedShinyText";
import { BsArrowRight } from "react-icons/bs";
import MeshBg from "../../components/MeshBg";
import BorderBeam from "../../components/BorderBeam";
import { FaRegCirclePlay } from "react-icons/fa6";
import BrandVideo from "./Video";

type LogoProps = {
  src: string;
  alt: string;
  className: string;
};
const Logo: React.FC<LogoProps> = ({ src, alt, className }) => (
  <img loading="eager" src={src} alt={alt} className={className} />
);
const logos: LogoProps[] = [
  {
    src: images.google,
    alt: "Google logo",
    className: "object-contain max-w-full h-[4rem]",
  },
  {
    src: images.microsoft,
    alt: "Microsoft logo",
    className: "object-contain max-w-full h-[5rem]",
  },
];

const HeroSection: React.FC = () => {
  const [visible, setVisible] = React.useState<boolean>(false);

  return (
    <div className="bg-white">
      <div className="relative isolate px-6 lg:pt-4 pt-14 lg:px-8">
        <MeshBg className="!-top-40 !sm:-top-80" />
        <div className="mx-auto max-w-2xl pb-16 sm:pb-32 lg:pb-40  sm:pt-16 lg:pt-24">
          <div className="w-full flex justify-center max-md:my-4">
            <div
              className={`group w-fit rounded-full border sm:mb-8 border-primaryBlue/5 text-base text-white transition-all ease-in hover:cursor-pointer hover:bg-lightBlue/5`}
            >
              <AnimatedShinyText className="flex gap-2 items-center justify-center px-4 py-1 transition ease-out hover:text-neutral-600 hover:duration-300">
                <MdOutlineCelebration />
                <span>Exciting</span>
                <span className="font-semibold text-primaryBlue">
                  announcement
                </span>
                <BsArrowRight className="ml-1 size-3 transition-transform duration-300 ease-in-out group-hover:translate-x-0.5" />
              </AnimatedShinyText>
            </div>
          </div>
          <div className="text-center">
            <h1 className="text-7xl font-bold tracking-tight max-md:text-4xl">
              Business Intelligence From Your Email
            </h1>
            <p className="block mt-6 mb-0 text-lg text-gray-600">
              Transform your mailbox into a powerful management tool
            </p>
            <p className="block text-lg text-gray-600">
              Make informed decisions, optimize performance, and drive growth
              with our cutting-edge awareness tools.
            </p>
            {/* <Link to="/get-started/sign-up">
              <img
                loading="lazy"
                src={images.heroSection}
                alt="Email analytics visualization"
                className="mt-8 max-md:w-full z-10 w-full transition-all duration-300 ease-out hover:scale-105"
              />
            </Link> */}
            <div
              onClick={() => setVisible(true)}
              className="cursor-pointer mt-8 relative group inline-flex rounded-full before:absolute before:inset-0 before:-z-10 before:scale-[.85] before:animate-[pulse_4s_cubic-bezier(.4,0,.6,1)_infinite] before:bg-gradient-to-b before:from-blue-500/30 before:to-blue-400/50 before:blur-3xl"
            >
              <img
                src={images.heroRing}
                alt="Contacts Rings"
                className="transition-opacity duration-300 ease-out  group-hover:opacity-50"
              />
              <div className="bg-primaryBlue group-hover:bg-[#2C5CFF] border border-lightBlue text-white rounded-full absolute top-[46%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 flex items-center gap-4 max-md:py-2 max-md:px-4 px-14 py-3">
                <FaRegCirclePlay size={25} />
                <span className="max-md:text-xs text-lg font-semibold text-nowrap group-hover:font-bold">
                  WATCH VIDEO
                </span>
                <BorderBeam
                  size={75}
                  duration={8}
                  delay={9}
                  borderWidth={3}
                  colorFrom={"#fee840"}
                  colorTo={"#feaa40"}
                  className="rounded-full"
                />
              </div>
              <div className="pointer-events-none" aria-hidden="true">
                <div>
                  <img
                    className="absolute max-md:size-12 size-24 left-32 max-md:left-14 max-md:-top-4 top-0 z-10 animate-[float_4s_ease-in-out_infinite_both]"
                    src={images.heroAvatar01}
                    alt="Avatar 01"
                  />
                  <img
                    className="absolute max-md:size-8 size-20 left-16 bottom-7 z-10 animate-[float_4s_ease-in-out_infinite_1s_both]"
                    src={images.heroAvatar02}
                    alt="Avatar 02"
                  />
                  <img
                    className="absolute max-md:size-12 size-24 right-20 max-md:right-12 bottom-5 max-md:bottom-2 z-10 animate-[float_4s_ease-in-out_infinite_2s_both]"
                    src={images.heroAvatar03}
                    alt="Avatar 03"
                  />
                  <img
                    className="absolute max-md:size-8 size-16 top-12 right-64 z-10 animate-[float_4s_ease-in-out_infinite_3s_both]"
                    src={images.heroAvatar04}
                    alt="Avatar 04"
                  />
                  <img
                    className="absolute max-md:size-8 size-16 -top-4 right-32 max-md:right-24 z-10 animate-[float_4s_ease-in-out_infinite_3s_both]"
                    src={images.heroAvatar05}
                    alt="Avatar 05"
                  />
                  <img
                    className="absolute max-md:size-8 size-16 bottom-16 max-md:bottom-8 right-64 max-md:right-4 z-10 animate-[float_4s_ease-in-out_infinite_3s_both]"
                    src={images.heroAvatar06}
                    alt="Avatar 06"
                  />
                </div>
              </div>
            </div>
            <div className="flex relative flex-col justify-center self-stretch px-20 pt-10 pb-2 mt-8 w-full max-md:px-5 max-md:max-w-full">
              <p className="self-center text-sm">
                {"Trusted by the world's most innovative teams"}
              </p>
              <div className="flex gap-5 justify-center items-center mt-4 pb-4 max-md:flex-wrap max-md:mr-2.5 max-md:max-w-full">
                {logos.map((logo, index) => (
                  <Logo key={index} {...logo} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <MeshBg innerClassName="!left-0 right-[calc(50%+3rem)]" />
      </div>
      <BrandVideo visible={visible} setVisible={setVisible} />
    </div>
  );
};

export default HeroSection;
