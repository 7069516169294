import { useState } from "react";
import { useAlertsContext } from "../../../../hooks/useAlertsContext";
import AuthSelectors from "../../../../modules/auth/selectors";
import { UpdateProfile } from "../../../../modules/auth/service";
import AuthActions from "../../../../modules/auth/actions";
import { useAppDispatch } from "../../../../hooks/useStore";

const useProfile = () => {
  const { user } = AuthSelectors();
  const { fetchProfile } = AuthActions();
  const dispatch = useAppDispatch();
  const { toastSuccess, toastError } = useAlertsContext();
  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState(user?.organization || "");

  const submitProfile = async () => {
    if (!organization) {
      toastError("Organization is required!");
    } else {
      setLoading(true);
      await UpdateProfile(organization)
        .then((response) => {
          dispatch(fetchProfile());
          toastSuccess(response.message);
        })
        .catch((e) => console.log(e))
        .finally(() => setLoading(false));
    }
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrganization(e.target.value);
  };

  return {
    user,
    loading,
    organization,
    onChange,
    submitProfile,
  };
};
export default useProfile;
