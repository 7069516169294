import { ISubscription } from "../../../utils/types";
import { cn } from "../../../utils/utils";

const SubscriptionBar = ({ subscription }: { subscription: ISubscription }) => {
  const { searches } = subscription;
  return (
    <div className="w-full my-2">
      {/* <span className="font-bold text-base block">Welcome,</span> */}
      <span className=" text-sm block">Trial subscription</span>
      <div className="flex flex-wrap justify-between mt-1">
        <span className="text-xs block font-bold">
          {searches?.searchesPercentageUsed}% Used
        </span>
        <span className="text-xs font-normal block">
          {searches?.searchesLeft} searches left
        </span>
      </div>
      <div className="w-full bg-gray-300 rounded-full h-1 mt-1">
        {searches?.searchesPercentageUsed
          ? searches?.searchesPercentageUsed > 0 && (
              <div
                style={{
                  width: `${searches?.searchesPercentageUsed}%`,
                }}
                className={cn("bg-primaryBlue rounded-full h-full")}
              />
            )
          : null}
      </div>
    </div>
  );
};

export default SubscriptionBar;
